export const noCollection = 'no-collection-info';
export const noCollectionArray = [noCollection];

export function isNoCollection (colls) {
  return colls === noCollectionArray;
}

export function processCollections (colls) {
  if (!colls || colls.length === 0) {
    return noCollectionArray;
  }
  return colls;
}
