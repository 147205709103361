import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import random from 'lodash/random';

import { jobProgressTypes } from './job-progress-types';

import { prepareUrl } from '../../utils/api-url-processor';
import Config from '../../config';
import fetchQueue from '../../utils/queue/fetch-queue';
import { sleep } from '../../utils/sleep';

import { NO_CAPTURES } from './changes-fetch-errors';
import decompress from './decompress';

function isAborted (err) {
  return err.name === 'AbortError';
}

export default class ChangesFetchJob {
  constructor (payload) {
    this._payload = payload;
    this._abortController = new window.AbortController();
    this._processPromise = null;
  }

  _calculateSimhash (url, year) {
    const signal = this._abortController.signal;

    return fetchQueue.add(
      () => window.fetch(
        prepareUrl(Config.api_url_simhash, {
          resource: 'calculate-simhash',
          ops: { year, url },
          compress: Config.changes_calendar.fetch_compressed ? '1' : ''
        }),
        { signal }
      )
        .then(res => res.json())
    );
  }

  _getJobStatus (jobId) {
    const signal = this._abortController.signal;

    /* eslint-disable camelcase */
    return fetchQueue.add(
      () => window.fetch(
        prepareUrl(Config.api_url_simhash, {
          resource: 'job',
          ops: { job_id: jobId },
          compress: Config.changes_calendar.fetch_compressed ? '1' : ''
        }),
        { signal }
      )
        .then(res => res.json())
    );
    /* eslint-enable camelcase */
  }

  _fetchSimhash (url, year) {
    const signal = this._abortController.signal;

    return fetchQueue.add(
      async () => {
        const res = await window.fetch(
          prepareUrl(Config.api_url_simhash, {
            resource: 'simhash',
            ops: { year, url },
            compress: Config.changes_calendar.fetch_compressed ? '1' : ''
          }),
          { signal }
        );

        let json = await res.json();

        if (json.status === 'error' && json.message === NO_CAPTURES) {
          throw new Error(json.message);
        }

        if (Config.changes_calendar.fetch_compressed) {
          json = decompress(json);
        }

        return json;
      }
    );
  }

  _isValidSimhash (simhash) {
    return !isEmpty(simhash) && simhash.simhash !== 'None';
  }

  follow () {
    return this._processPromise;
  }

  /**
   * If all key values are equal to payload of the job
   *
   * @param payload
   * @returns {boolean}
   */
  isEqual (payload) {
    return Object.entries(payload).every(([key, value]) => this._payload[key] === value);
  }

  stop () {
    this._abortController.abort();
    this._pausePromiseResolver && this._pausePromiseResolver();
    this._stopped = true;
  }

  /**
   * will stop asking job status
   */
  pause () {
    this._jobTrackingPausePromise = new Promise((resolve) => {
      this._pausePromiseResolver = resolve;
    });
  }

  /**
   * resume asking job status
   */
  resume () {
    this._pausePromiseResolver && this._pausePromiseResolver();
    this._pausePromiseResolver = null;
  }

  async start ({ jobId, url, year }, options) {
    const process = Promise.resolve()
      .then(async () => {
        if (Config.changes_calendar.mock_data) {
          return range(356)
            .map(() => {
              const month = random(1, 12).toString().padStart(2, '0');
              const day = random(1, 30).toString().padStart(2, '0');
              const hour = random(0, 24);
              const hash = range(8)
                .map(() => String.fromCharCode(random(0, 255)));
              return [
                `2000${month}${day}${hour}0000`,
                btoa(hash)
              ];
            });
        }

        options.onProgress(jobProgressTypes.FETCHING);
        try {
          let simhash = await this._fetchSimhash(url, year);
          if (this._isValidSimhash(simhash.captures)) {
            options.onProgress(jobProgressTypes.DONE);
            return simhash.captures;
          }

          if (!jobId) {
            options.onProgress(jobProgressTypes.CALCULATING);
            const res = await this._calculateSimhash(url, year);
            jobId = res.job_id;
          }

          let status = 'PENDING';
          const retryCountInitial = 3;
          let retryCount = retryCountInitial;

          while (status === 'PENDING' || status === 'error') {
            if (this._jobTrackingPausePromise) {
              await this._jobTrackingPausePromise;
              this._jobTrackingPausePromise = null;
            }

            try {
              const res = await this._getJobStatus(jobId);
              status = res.status;

              if (status === 'error') {
                throw new Error(res.info);
              }

              options.onProgress(jobProgressTypes.CALCULATING, {
                status,
                jobId,
                info: res.info
              });

              // set back retry count
              retryCount = retryCountInitial;

              // TODO: once in a while we could update heatmap
            } catch (err) {
              if (isAborted(err)) {
                return null;
              }
              // TODO: should warning in UI
              console.warn(`request of job status has failed for ${url} of ${year}`, err);
              if (--retryCount < 0) {
                // propagate forward
                throw err;
              }
            }

            // wait a second
            await sleep(Config.changes_calendar.delay_before_job_state_request || 1000.0);
            if (this._stopped) {
              return null;
            }
          }

          options.onProgress(jobProgressTypes.FETCHING);
          simhash = await this._fetchSimhash(url, year);
          if (this._isValidSimhash(simhash.captures)) {
            options.onProgress(jobProgressTypes.DONE);
          }
          return simhash.captures || [];
        } catch (err) {
          if (isAborted(err)) {
            return null;
          }

          // propagate forward
          throw err;
        }
      });

    this._processPromise = process;

    return process;
  }
}
