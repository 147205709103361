import './summary-fallback.less';

import React, { memo } from 'react';

const SummaryFallback = () => (
  <div className="summary-fallback">
    <div className="summary-fallback-headline"/>
    <div className="summary-fallback-item"/>
    <div className="summary-fallback-item"/>
    <div className="summary-fallback-item"/>
    <div className="summary-fallback-item"/>
  </div>
);

SummaryFallback.displayName = 'SummaryFallback';

export default memo(SummaryFallback);
