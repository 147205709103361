import './search-input.less';

import PropTypes from 'prop-types';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Config from '../../config';
import { canIUseBetaFeature } from '../../utils/can-i-use-beta-feature';

import { debouncePromise, ifNotDropped } from '../../utils/debounce-promise';

class SearchInput extends React.Component {
  static displayName = 'SearchInput';

  static propTypes = {
    autoSuggestionInvalid: PropTypes.bool,
    autoSuggestionInProgress: PropTypes.bool,
    autoSuggestionList: PropTypes.array,
    hasAutoSuggestion: PropTypes.bool,
    text: PropTypes.string,

    onFetchSuggestions: PropTypes.func.isRequired,
    onSelectSearchOption: PropTypes.func.isRequired,
    onUpdateSearchText: PropTypes.func.isRequired,
    onUpdateSearchTextByImmediateText: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this._debounceGetHosts = debouncePromise(Config.search.text_update_debounce_delay);
    this._searchInput = React.createRef();
    this.state = {
      hasChanged: false
    };
  }

  shouldComponentUpdate (nextProps) {
    return (nextProps.autoSuggestionInvalid !== this.props.autoSuggestionInvalid ||
            nextProps.autoSuggestionInProgress !== this.props.autoSuggestionInProgress ||
            !isEqual(nextProps.autoSuggestionList, this.props.autoSuggestionList) ||
            nextProps.hasAutoSuggestion !== this.props.hasAutoSuggestion ||
            nextProps.text !== this.props.text);
  }

  /**
   * Fire on Enter is pressing
   * Special case: If we are already in the urlquery page (ends with *), avoid
   * a text query ending with * because it will create a URL ending with **
   * which is is crashing react-router-dom Regex parser.
   * @param event
   */
  handleSearchSubmit = (event) => {
    const { onUpdateSearchTextByImmediateText, text } = this.props;
    this._searchInput.current.blur();
    event.preventDefault();

    if (this.props.selectedView == "urlquery") {
      const path = `/web/*/${text}*`;
      if (path !== window.location.pathname) {
        this.props.history.push(path);
      }
      return;
    }
    (text.length > 0) && onUpdateSearchTextByImmediateText();
  };

  /**
   * fire on selection from drop down list
   *
   * @param items chosen
   */
  handleChange = (items) => {
    if (Array.isArray(items) && items.length > 0) {
      this.props.onSelectSearchOption(items[0].label);
    }

    this.setState({
      hasChanged: true
    });
  };

  _fetchSuggestion () {
    const {
      hasAutoSuggestion,
      onFetchSuggestions
    } = this.props;

    if (!hasAutoSuggestion) {
      this._debounceGetHosts()
        .then(onFetchSuggestions)
        .catch(ifNotDropped((err) => {
          console.warn(err);
        }));
    }
  }

  componentDidMount () {
    this._fetchSuggestion();
  }

  /**
   * When query text is changed from non-empty to empty, clear SearchInput.
   * This happens when you click on the homepage logo after a search.
   */
  componentDidUpdate (prevProp) {
    const { text } = this.props;
    if (text === '') {
      this._searchInput.current.clear();
    }

    if (prevProp.text !== text) {
      this._fetchSuggestion();
    }
  }

  render () {
    let {
      autoSuggestionList, autoSuggestionInProgress, text, onUpdateSearchText
    } = this.props;

    if (!autoSuggestionList || autoSuggestionList.length === 0) {
      autoSuggestionList = [{
        label: text
      }];
    }

    return (
      <form
        onSubmit={this.handleSearchSubmit}
      >
        <AsyncTypeahead
          autoFocus={true}
          filterBy={() => true}
          id="search-input"
          isLoading={autoSuggestionInProgress}
          submitFormOnEnter={true}
          clearButton={true}
          minLength={Config.search.query_min_length}
          onChange={this.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              this.handleSearchSubmit(e);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onInputChange={onUpdateSearchText}
          open={this.state.hasChanged ? undefined : false}
          onSearch={
            // for the moment AsyncTypeahead doesn't expect promise
            // as response of this function but uses `isLoading` field
            //
            // onRequestAutocompleteList
            //
            // this handler is required in AsyncTypeahead
            // but we actually don't need it
            // because we update options when we have new text
            // so this handler looks redundant
            () => {}
          }
          options={autoSuggestionList}
          placeholder="Enter a URL or words related to a site’s home page"
          ref={this._searchInput}
          selected={
            autoSuggestionList && text ? [{ label: text || '' }] : []
          }
          useCache={false}
        />
        <input
          type="submit"
          className="hidden-submit-btn"
          tabIndex="-1"
        />
        {
          canIUseBetaFeature('beta_promo') ? (
            <div className="beta-promo">
              <a href="https://web-beta.archive.org/">
                Try our new version of Search (Beta)
              </a>
            </div>
          ) : null
        }
      </form>
    );
  }
}

export default React.memo(SearchInput);
