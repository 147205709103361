import { createSelector } from '@reduxjs/toolkit';

import timestampToYear from '../../utils/datetime/timestamp-to-year';

const getChangesCalendar = state => state.ui.changesCalendar;

export const getDayPopupSettings = createSelector(
  [getChangesCalendar],
  (changesCalendar) => changesCalendar.dayPopupSettings
);

export const getTimestampA = createSelector(
  [getChangesCalendar],
  (changesCalendar) => changesCalendar.timestampA
);

export const getTimestampAYear = createSelector(
  [getTimestampA],
  (timestampA) => timestampA && timestampToYear(timestampA)
);

export const getTimestampB = createSelector(
  [getChangesCalendar],
  (changesCalendar) => changesCalendar.timestampB
);

export const getTimestampBYear = createSelector(
  [getTimestampB],
  (timestampB) => timestampB && timestampToYear(timestampB)
);

export const getMainFieldName = createSelector(
  [getTimestampA],
  (timestampA) => timestampA ? 'distance' : 'delta'
);
