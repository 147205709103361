import { createReducer } from '@reduxjs/toolkit';

const NAME_SPACE = 'UI/SEARCH_RESULT';

// actions

export const actionTypes = {
  SELECT_VIEW: `${NAME_SPACE}:SELECT_VIEW`
};

export const selectView = (view) => ({
  type: actionTypes.SELECT_VIEW,
  payload: { view }
});

const initialState = { view: null };

export default createReducer(initialState, (builder) => {
  builder.addCase(actionTypes.SELECT_VIEW, (draft, { payload: { view } }) => {
    draft.view = view;
  });
});
