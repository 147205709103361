import ChangesFetchJob from './changes-fetch-job';

export class ChangesFetchService {
  constructor () {
    this._jobs = {};
  }

  _payloadToId ({ url, year }) {
    return `${url}/${year}`;
  }

  _getJobByPayload (payload) {
    return this._jobs[this._payloadToId(payload)];
  }

  _addJob (payload, job) {
    const id = this._payloadToId(payload);
    this._jobs[id] = job;
  }

  _removeJob (payload) {
    const id = this._payloadToId(payload);
    delete this._jobs[id];
  }

  start (payload, options) {
    let job = this._getJobByPayload(payload);
    if (job) {
      job.resume();
      return job.follow();
    }

    job = new ChangesFetchJob(payload);
    this._addJob(payload, job);
    return job.start(payload, options)
      .catch(e => {
        this._removeJob(payload, job);
        return Promise.reject(e);
      });
  }

  stop (payload) {
    const job = this._getJobByPayload(payload);
    if (!job) {
      return false;
    }
    job.stop();
    return true;
  }

  _stop = (job) => {
    job.stop();
    this._removeJob(job._payload);
  };

  stopAllOf ({ url }) {
    return Object.values(this._jobs)
      .filter(job => job.isEqual({ url }))
      .map(this._stop);
  }

  pauseJobTracking (payload) {
    const job = this._getJobByPayload(payload);
    if (!job) {
      return false;
    }
    job.pause();
    return true;
  }

  resumeJobTracking (payload) {
    const job = this._getJobByPayload(payload);
    if (!job) {
      return false;
    }
    job.resume();
    return true;
  }
}
