import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import { connect, Provider } from 'react-redux';

import { App } from './components/app';
import { SearchResultOrUrlDetailsContainer } from './components/search-result-or-url-details';
import waitingComponent from './hocs/waiting-lazy';

import { HideWhen } from './hocs/hide-when';
import { hasCapturesOfSubmittedQuery, hasCapturesOfSubmittedQueryIsValid } from './selectors/ui/calendar';
import { isQueryUrl, isQueryUrlValid } from './selectors/ui/hosts';

// hide WrappedComponent either query is invalid or query is URL and search result is empty
// this is a combination of hideWhenNoCaptures and hideWhenIsUrlIsInvalid.
function hideWhenNonUrlOrNoCaptures () {
  return (WrappedComponent) => {
    const C = connect(
      (state, props) => ({
        condition: !isQueryUrlValid(state, props) ||
          isQueryUrl(state, props) &&
          hasCapturesOfSubmittedQueryIsValid(state, props) &&
          !hasCapturesOfSubmittedQuery(state, props),
        component: WrappedComponent
      })
    )(HideWhen);
    C.displayName = 'HideWhenNonUrlOrNoCaptures';
    return C;
  };
}

function hideWhenNoCaptures () {
  return (WrappedComponent) => {
    const C = connect(
      (state, props) => ({
        condition: isQueryUrl(state, props) &&
          hasCapturesOfSubmittedQueryIsValid(state, props) &&
          !hasCapturesOfSubmittedQuery(state, props),
        component: WrappedComponent
      })
    )(HideWhen);
    C.displayName = 'HideWhenNoCaptures';
    return C;
  };
}

function hideWhenIsUrlIsInvalid () {
  return (WrappedComponent) => {
    const C = connect(
      (state, props) => ({
        condition: !isQueryUrlValid(state, props),
        component: WrappedComponent
      })
    )(HideWhen);
    C.displayName = 'HideWhenIsUrlIsInvalid';
    return C;
  };
}

import { historyUpdater } from './hocs/history-updater';
import { redirectIf } from './hocs/redirect-if';
import { redirectIfSearching } from './hocs/redirect-if-searching';
import { redirectWhenIsNotUrl } from './hocs/redirect-when-is-not-url';

import ChangesHeatmapFallback from './components/changes-heatmap/changes-heatmap-fallback';
import CollectionsLayoutFallback from './components/collections/collections-layout-fallback';
import SummaryFallback from './components/summary/summary-fallback';
import RevolvingLandingFallback from './components/welcome/revolving-landing-fallback';
import SitemapLayoutFallback from './components/sitemap/sitemap-layout-fallback';
import WelcomeGridFallback from './components/welcome/welcome-fallback';

import * as hostSelector from './selectors/ui/hosts';
import * as searchRequestSelector from './selectors/ui/search-request';
import { canIUseBetaFeature } from './utils/can-i-use-beta-feature';
import { types } from './utils/query-type';
import { templateWebSlashTimestampSlashText, templateSearchText,
         templateWebDiffTs1SlashTs2SlashText } from './utils/route-templates';

// lazy loading bundles

const ChangesContainer = React.lazy(
  () => import(/* webpackChunkName: "changes" */ './components/changes-heatmap')
);
const CollectionsContainer = React.lazy(
  () => import(/* webpackChunkName: "collections" */ './components/collections')
);
const Diff = waitingComponent(React.lazy(
  () => import(/* webpackChunkName: "diff" */ './components/diff/diff')
));
const RevolvingLanding = React.lazy(
  () => import(/* webpackChunkName: "revolving-landing" */ './components/welcome/revolving-landing')
);
const SiteMapContainer = React.lazy(
  () => import(/* webpackChunkName: "sitemap" */ './components/sitemap')
);
const SummaryContainer = React.lazy(
  () => import(/* webpackChunkName: "summary" */ './components/summary')
);
const Sunburst = waitingComponent(React.lazy(
  () => import(/* webpackChunkName: "diff-sunburst" */ './components/diff/sunburst')
));
const WelcomeGridPanel = React.lazy(
  () => import(/* webpackChunkName: "welcome" */'./components/welcome')
);
const AdvancedSearchContainer = React.lazy(
  () => import(/* webpackChunkName: "advanced-search" */ './components/advancedsearch')
);
const UrlQueryResult = React.lazy(
  () => import(/* webpackChunkName: "url-query" */ './components/url-query')
);
const AllCollectionsContainer = React.lazy(
  () => import(/* webpackChunkName: "collection-search" */ './components/all-collections')
);

const RedirectToWebPath = ({ match }) => (
  <Redirect to={match.params.query ? '/web/*/' + match.params.query : ''}/>
);

RedirectToWebPath.propTypes = {
  match: PropTypes.object.isRequired
};

const RevolvingLandingPage = historyUpdater({
  template: templateWebSlashTimestampSlashText
})(waitingComponent(RevolvingLanding, RevolvingLandingFallback));

const WelcomeGridPanelPage = historyUpdater({
  template: templateWebSlashTimestampSlashText
})(waitingComponent(WelcomeGridPanel, WelcomeGridFallback));

const DETAILS_TYPES = [types.DOMAIN, types.HOST, types.TLD, types.WAYBACK_SUMMARY];
const SITEMAP_TYPES = [types.DOMAIN, types.HOST];

/**
 * HOC which redirect to /details/ if we give explicit defined query type
 */
const redirectToDetailsIfExplicitDefinedQueryType = redirectIfSearching({
  condition: ({ explicitType }) => DETAILS_TYPES.includes(explicitType),
  to: '/details/{{text}}'
});

const AppRouter = ({ history, store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Suspense fallback={<div className="text-center">Loading...</div>}>
          <Switch>
            <Route
              path="/" exact={true}
              render={
                (params) => {
                  switch (canIUseBetaFeature('landing')) {
                    case 'grid':
                      return <WelcomeGridPanelPage {...params}/>;
                    case 'carousel':
                    default:
                      return <RevolvingLandingPage {...params}/>;
                  }
                }
              }
            />
            <Route path="/:collection/:timestamp([-\d]+)?\*/:url(.+?)\*" exact component={UrlQueryResult}/>
            <Route path="/:collection/:timestamp([-\d]+)?\*;:pathParams/:url(.+?)\*" exact component={UrlQueryResult}/>
            <Route
              path="/details/*"
              component={
                flow([
                  hideWhenNoCaptures(),
                  hideWhenIsUrlIsInvalid(),
                  redirectIf({
                    condition: ({ state, props }) => {
                      const isUrlValid = hostSelector.isQueryUrlValid(state, props);
                      const explicitType = searchRequestSelector.getExplicitQueryType(state, props);
                      const implicitType = searchRequestSelector.getImplicitQueryType(state, props);
                      const text = searchRequestSelector.getSubmittedQueryText(state, props);
                      return isUrlValid &&
                        !DETAILS_TYPES.includes(explicitType) &&
                        implicitType !== types.URL &&
                        text !== '';
                    },
                    to: '/web/{{timestamp}}/{{text}}'
                  }),
                  historyUpdater({
                    id: 'details',
                    template: '/details/{{text}}'
                  })
                ])(waitingComponent(SummaryContainer, SummaryFallback))
              }
            />
            {
              canIUseBetaFeature('changes_calendar') && (
                <Route
                  path="/web/changes/(.+)"
                  component={
                    flow([
                      hideWhenNonUrlOrNoCaptures(),
                      redirectWhenIsNotUrl({
                        to: '/web/{{timestamp}}/{{text}}'
                      }),
                      historyUpdater({
                        id: 'changes',
                        template: '/web/changes/{{text}}'
                      })
                    ])(waitingComponent(ChangesContainer, ChangesHeatmapFallback))
                  }
                />
              )
            }
            <Route
              path="/web/collections/([^/]*)/(.+)"
              component={
                flow([
                  hideWhenNonUrlOrNoCaptures(),
                  redirectWhenIsNotUrl({
                    to: '/web/{{timestamp}}/{{text}}'
                  }),
                  historyUpdater({
                    id: 'collections',
                    template: '/web/collections/{{timestamp}}/{{text}}'
                  })
                ])(waitingComponent(CollectionsContainer, CollectionsLayoutFallback))
              }
            />
            <Route
              path="/web/diff/([^/]*)/([^/]*)/(.+)"
              component={
                flow([
                  historyUpdater({
                    id: 'diff',
                    template: templateWebDiffTs1SlashTs2SlashText
                  })
                ])(Diff)
              }
            />
            <Route
              path="/web/diffgraph/([^/]*)/(.+)"
              component={
                flow([
                  historyUpdater({
                    id: 'diffgraph',
                    template: '/web/diffgraph/{{timestamp}}/{{text}}'
                  })
                ])(Sunburst)
              }
            />
            <Route
              path="/web/sitemap/(.+)"
              component={
                flow([
                  hideWhenNonUrlOrNoCaptures(),
                  redirectIfSearching({
                    condition: ({ explicitType }) => {
                      return [
                        types.TLD, types.WAYBACK_SUMMARY
                      ].includes(explicitType);
                    },
                    to: '/details/{{text}}'
                  }),
                  redirectIf({
                    condition: ({ state, props }) => {
                      const isUrlValid = hostSelector.isQueryUrlValid(state, props);
                      const explicitType = searchRequestSelector.getExplicitQueryType(state, props);
                      const implicitType = searchRequestSelector.getImplicitQueryType(state, props);
                      return isUrlValid &&
                        !SITEMAP_TYPES.includes(explicitType) &&
                        implicitType !== types.URL;
                    },
                    to: '/web/{{timestamp}}/{{text}}'
                  }),
                  historyUpdater({
                    id: 'sitemap',
                    template: '/web/sitemap/{{text}}'
                  })
                ])(waitingComponent(SiteMapContainer, SitemapLayoutFallback))
              }
            />
            <Route
              path='/collection-search/:collection/(.*)'
              component={
                flow([
                  historyUpdater({
                    id: 'advancedsearch',
                    template: templateSearchText
                  })
                ])(AdvancedSearchContainer)
              }
            />
            <Route
              path='/collection-search/:collection'
              component={
                flow([
                  historyUpdater({
                    id: 'advancedsearch',
                    template: templateSearchText
                  })
                ])(AdvancedSearchContainer)
              }
            />
            <Route
              path='/collection-search'
              component={
                flow([
                  historyUpdater({
                    id: 'collection-search',
                    template: '/collection-search'
                  })
                ])(AllCollectionsContainer)
              }
            />
            <Route
              path="/web/([^/]+)/(.+)"
              component={
                flow([
                  hideWhenNonUrlOrNoCaptures(),
                  redirectToDetailsIfExplicitDefinedQueryType,
                  historyUpdater({
                    id: 'calendar',
                    template: templateWebSlashTimestampSlashText
                  })
                ])(SearchResultOrUrlDetailsContainer)
              }
            />
            <Route path="/:query" exact={true} component={RedirectToWebPath}/>
          </Switch>
        </Suspense>
      </App>
    </BrowserRouter>
  </Provider>
);

AppRouter.displayName = 'AppRouter';

AppRouter.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default AppRouter;
