import { createReducer } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import Config from '../../config';
import { prepareUrl } from '../../utils/api-url-processor';
import { canIUseBetaFeature } from '../../utils/can-i-use-beta-feature';
import { fetchOnSelectorsAction } from '../../utils/fetch-action';
import { getSearchItemsResult } from '../../utils/get-search-items-result';

import {
  getSubmittedQueryText,
  getSubmittedQuerySize,
  getSubmittedQueryPage,
  getSubmittedQueryTypes,
  getSubmittedQueryTypesAndText,
  getSubmittedQueryCollection
} from '../../selectors/ui/search-request';
import { isSearchResultInProgress } from '../../selectors/ui/search-result';

const namespace = 'ENTITIES/SEARCH_RESULT';

export const actionTypes = {
  REQUEST_EMPTY_SEARCH: `${namespace}/REQUEST_EMPTY_SEARCH`,
  SEARCH_RESULT_REQUEST: `${namespace}/REQUEST`,
  SEARCH_RESULT_RECEIVE: `${namespace}/RECEIVE`,
  SEARCH_RESULT_ERROR: `${namespace}/ERROR`,
  SEARCH_RESULT_INVALIDATE: `${namespace}/INVALIDATE`
};

export const invalidateCurrentResult = () => (dispatch, getState) => {
  const query = getSubmittedQueryTypesAndText(getState());
  return dispatch({
    type: actionTypes.SEARCH_RESULT_INVALIDATE,
    payload: { query }
  });
};

export function requestEmptySearch (query) {
  return {
    type: actionTypes.REQUEST_EMPTY_SEARCH,
    ...query
  };
}

export const fetchSearchResultForCurrentSearchQuery = fetchOnSelectorsAction({
  selectors: {
    text: getSubmittedQueryText,
    size: getSubmittedQuerySize,
    page: getSubmittedQueryPage,
    types: getSubmittedQueryTypes,
    collection: getSubmittedQueryCollection
  },

  ignoreIf: (state) => isSearchResultInProgress(state),

  getUrl: ({ text, size, page, types, collection }) => {
    if (collection) {
      return prepareUrl(Config.api_url_search_collection, {
        url: encodeURIComponent(text),
        size,
        page,
        types,
        collection
      });
    }
    if (canIUseBetaFeature('search') === 'v2') {
      return prepareUrl(Config.api_url_search, {
        url: encodeURIComponent(text),
        size,
        types
      });
    }

    return prepareUrl(Config.api_url_anchor, {
      query: encodeURIComponent(text),
      size,
      types
    });
  },

  actions: [
    actionTypes.SEARCH_RESULT_REQUEST, actionTypes.SEARCH_RESULT_RECEIVE, actionTypes.SEARCH_RESULT_ERROR
  ]
});

function processResult (action) {
  let key;
  // Search v2 results contain items in `hits` key. Search v1 doesn't have that
  if ('hits' in action.res) {
    key = Config.search_v2.uid_field_name;
  } else {
    key = Config.search.uid_field_name;
  }
  let items = getSearchItemsResult(action.res).map(item => item[key]);
  items = uniq(items);
  let noResultFound;
  if (action || action.res.total === 0) {
    noResultFound = true;
  }

  return {
    items,
    // we have none empty query but no result found
    noResultFound,
    didYouMean: action.res.did_you_mean,
    total: action.res.total,
    aggregations: action.res.aggregations
  };
}

/**
 * convert query to UID
 * @param types
 * @param text
 */
export function getId ({ types, text }) {
  return `types=${types} text=${text}`;
}

const initialState = {};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.SEARCH_RESULT_REQUEST, (draft, action) => {
      draft[getId(action)] = {
        inProgress: true,
        updatedAt: action.updatedAt,
      };
    })
    .addCase(actionTypes.REQUEST_EMPTY_SEARCH, (draft, action) => {
      draft[getId(action)] = {
        inProgress: false,
        invalid: false,
        total: 0,
        items: [],
        updatedAt: action.updatedAt,
      };
    })
    .addCase(actionTypes.SEARCH_RESULT_RECEIVE, (draft, action) => {
      draft[getId(action)] = {
        inProgress: false,
        invalid: false,
        updatedAt: action.updatedAt,
        ...processResult(action),
      };
    })
    .addCase(actionTypes.SEARCH_RESULT_ERROR, (draft, action) => {
      draft[getId(action)] = {
        inProgress: false,
        invalid: false,
        updatedAt: action.updatedAt,
        error: action.error,
      };
    })
    .addCase(actionTypes.SEARCH_RESULT_INVALIDATE, (draft, { payload: { query } }) => {
      draft[getId(query)].invalid = true;
    });
});
