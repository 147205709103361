import { combineReducers } from 'redux';

import entities from './entities';
import ui from './ui';

export default function createRootReducer () {
  return combineReducers({
    entities,
    ui
  });
}
