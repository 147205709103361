import { sleep } from '../../utils/sleep';

export async function mapDay (data, handler, { delay = 0, onProgress, maxDuration = 20 } = {}) {
  let previousDay = null;

  const result = {};

  let lastSleepTime = Date.now();

  let index = 0;

  for (const [monthName, month] of Object.entries(data)) {
    if (!month) {
      // rough estimation
      index += 30;
      continue;
    }

    for (const [dayIdx, day] of Object.entries(month)) {
      index++;
      if (!day) {
        continue;
      }

      if (!result[monthName]) {
        result[monthName] = {};
      }

      result[monthName][dayIdx] = {
        ...day,
        ...handler(day, previousDay)
      };

      if (day.items.length > 0) {
        // ignore days without captures
        previousDay = day;
      }

      const newTime = Date.now();

      if (newTime - lastSleepTime > maxDuration) {
        await sleep(delay);
        lastSleepTime = newTime;
        onProgress && onProgress({ index, total: 365 });
      }
    }
  }

  return result;
}
