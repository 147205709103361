import './collections-layout-fallback.less';

import React, { memo } from 'react';

const CollectionsLayoutFallback = () => (
  <div className="collections-layout-fallback">
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
    <div className="collections-layout-fallback-item"/>
  </div>
);

CollectionsLayoutFallback.displayName = 'CollectionsLayoutFallback';

export default memo(CollectionsLayoutFallback);
