import forEach from 'lodash/forEach';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import range from 'lodash/range';

import { b64ToArray } from '../../utils/b64-to-array';
import * as hamming from '../../utils/hamming';
import { sleep } from '../../utils/sleep';
import timestamp2day from '../../utils/datetime/timestamp-to-day';
import timestamp2month from '../../utils/datetime/timestamp-to-month';
import timestamp2datetime from '../../utils/datetime/timestamp-to-datetime';

import { maxHashVariation } from '../../services/changes/max-hash-variation';

import { mapDay } from './map-day';
import * as palette from './palette';

/**
 * Number of histogram bins
 *
 * @type {number}
 */
export const numOfBins = 12;

/**
 * Map Simhash values to day
 *
 * @param data
 */
export function mapCalendarToDay (data) {
  return data.reduce((res, [timestamp, hash]) => {
    timestamp = Number.parseInt(timestamp);
    const dt = timestamp2datetime(timestamp);
    const month = dt.getUTCMonth();
    const day = dt.getUTCDate() - 1;
    if (!res[month]) {
      res[month] = {};
    }
    if (!res[month][day]) {
      res[month][day] = { items: [] };
    }
    res[month][day].items.push({
      timestamp,
      hash
    });
    return res;
  }, {});
}

/**
 * Map single capture from calendar data
 *
 * @private
 * @param data
 * @param callback
 * @returns {Object}
 */
function mapCapture (data, callback) {
  return mapValues(data,
    month => mapValues(month,
      day => ({ ...day, items: day.items.map(callback) })));
}

function forEachDay (data, callback) {
  forEach(data, month => forEach(month, day => callback(day)));
}

/**
 * Map base64 hash to binary array
 *
 * @param data
 * @returns {Object}
 */
export function mapBase64ToBinary (data) {
  return mapCapture(data, capture => ({
    ...capture,
    hash: b64ToArray(capture.hash)
  }));
}

/**
 * Calculate derivative distance for hash
 *
 * @param data
 * @returns {*}
 */
export function calcDelta (data) {
  let previousCapture;
  return mapCapture(data, capture => {
    let value;
    if (previousCapture === undefined) {
      value = 0;
    } else {
      value = hamming.distance(capture.hash, previousCapture.hash);
    }
    previousCapture = capture;
    return {
      ...capture,
      value
    };
  });
}

/**
 * Calculate distance to one hash
 *
 * @param data
 * @param targetHash
 * @returns {Object}
 */
export function calcDistanceTo (data, targetHash) {
  return mapCapture(data, capture => ({
    ...capture,
    value: hamming.distance(capture.hash, targetHash)
  }));
}

/**
 * Find the max distance between captures of the day
 * and the last capture of the previous day
 *
 * @param day
 * @param previousDay
 * @param ctx
 * @returns {number}
 */
export function maxDistanceOfDay (day, previousDay, ctx) {
  let items = day.items;
  if (previousDay && previousDay.items.length > 0) {
    items = items.concat(previousDay.items[previousDay.items.length - 1]);
  }

  // more accurate way to calculate max day distance: time complexity O(N^2)
  // return maxPairwiseDistanceBetweenHashes(items);

  // more efficient way to estimate day distance: time complexity O(N)
  return maxHashVariation(items, ctx);
}

function sumOneField (fieldName) {
  return (day) => day.items.reduce((acc, i) => acc + i[fieldName], 0);
}

/**
 * Calc sum delta field
 *
 * @private
 * @param day
 * @returns {number}
 */
export const sumDelta = sumOneField('delta');

/**
 * Calc sum distance field
 *
 * @private
 * @param day
 * @returns {number}
 */
export const sumDistance = sumOneField('distance');

export function getCaptureByTimestamp (data, timestamp) {
  const monthIdx = timestamp2month(timestamp) - 1;
  const dayIdx = timestamp2day(timestamp) - 1;
  const day = get(data, [monthIdx, dayIdx]);
  if (!day) {
    return null;
  }

  return day.items.find(i => i.timestamp === timestamp);
}

/**
 * Get one day capture by datetime
 *
 * @param data
 * @param datetime
 * @returns {*}
 */
export function getDayCapturesByDatetime (data, datetime) {
  const monthIdx = datetime.getUTCMonth();
  const dayIdx = datetime.getUTCDate() - 1;
  return get(data, [monthIdx, dayIdx, 'items']);
}

/**
 * Scan all days and find the biggest distance we have
 *
 * @param data
 */
export function findMaxFieldValue (data) {
  let maxValue = 0;

  mapValues(data,
    month => mapValues(month,
      day => {
        maxValue = Math.max(day.value, maxValue);
      }));

  return maxValue;
}

/**
 * Sort matrix by field index
 *
 * @param data
 * @param index
 * @returns {*}
 */
export function sortMatrix (data, index) {
  return data.sort((a, b) => {
    if (a[index] < b[index]) {
      return -1;
    }
    if (a[index] > b[index]) {
      return 1;
    }
    return 0;
  });
}

/**
 * Create bins
 *
 * @param min
 * @param max
 * @param size
 */
export function createBins ({ min, max, size, zero = false }) {
  const delta = max - min;
  const res = range(size)
    .map(idx => ({
      begin: min + delta * idx / size,
      end: min + delta * (idx + 1) / size,
      weight: 0.0
    }));

  if (zero) {
    res[0].begin += 0.1;
    res.unshift({
      begin: min,
      end: min + 0.1,
      weight: 0.0
    });
  }
  return res;
}

/**
 * Find bin by its value
 *
 * @param bins
 * @param value
 * @returns {}
 */
export function findBinByValue ({ bins, value, clamp = false }) {
  const res = bins.find(b => b.begin <= value && value < b.end);
  if (res !== undefined || bins.length === 0 || !clamp) {
    return res;
  }

  if (value < bins[0].begin) {
    return bins[0];
  }
  const last = bins.length - 1;
  if (value >= bins[last].end) {
    return bins[last];
  }

  throw new Error('Hm, it seems we have holes in bins');
}

/**
 * Normalize histogram
 *
 * @param bins
 * @returns [*]
 */
export function normalizeHistogram (bins) {
  const maxWeight = bins.reduce((acc, b) => Math.max(acc, b.weight), 0);
  return bins.map(b => ({
    ...b,
    weight: b.weight / maxWeight
  }));
}

/**
 * Put all data to histogram bins
 *
 * @param data
 * @param bins
 * @returns [*]
 */
export function putToHistogramBins ({ data, bins }) {
  forEachDay(data, day => {
    const { value } = day;
    const bin = findBinByValue({ bins, value, clamp: true });
    if (bin) {
      const weight = bin.weight || 0;
      bin.weight = weight + 1;
    }
  });

  return bins;
}

/**
 * Process data to get histogram
 *
 * @param data
 * @param maxValue
 * @param size
 * @returns {*[]}
 */
export function processHistogram ({ data, maxValue, size = 10 }) {
  let histogram = createBins({ zero: true, min: 0, max: maxValue, size });
  histogram = putToHistogramBins({
    data,
    bins: histogram
  });
  return normalizeHistogram(histogram);
}

/**
 * Process distance
 *
 * @param data
 * @param targetHash
 * @param onProcess
 *
 * @returns {*}
 */
export async function processDistance (data, targetHash, { onProgress }) {
  return mapDay(data, (day) => {
    // find distance from targetHash
    const items = day.items.map(capture => ({
      ...capture,
      value: hamming.distance(capture.hash, targetHash)
    }));

    // group for day
    return {
      items,
      value: items.reduce((acc, capture) => Math.max(acc, capture.value), 0)
    };
  }, { onProgress });
}

/**
 * Process simhash
 *
 * - map simhash list to year days
 * - evaluate delta for each capture
 * - evaluate delta variation per day
 *
 * @param data
 * @param url
 * @param year
 * @param delay
 * @param onProgress
 *
 * @returns {Promise<void>}
 */
export async function mapSimhashToCalendarAndEstimateVariation ({ data, url, year, delay = 20, onProgress }) {
  data = sortMatrix(data, 0);

  await sleep(delay);

  data = mapCalendarToDay(data);

  await sleep(delay);

  data = mapBase64ToBinary(data);

  await sleep(delay);

  data = calcDelta(data);

  await sleep(delay);

  data = await mapDay(data, (day, previousDay) => ({
    value: maxDistanceOfDay(day, previousDay, { url, year })
  }), { onProgress, delay: 0 });
  return data;
}

export function colorHistogram ({ histogram, maxValue }) {
  return histogram.map(b => ({
    ...b,
    color: b.begin === 0 ? palette.nothingWasChangedColor : palette.heatmapPaletteD3Color(1 + (b.begin + b.end) / (2 * maxValue))
  }));
}
