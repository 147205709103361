import inUTC from './in-utc';

const oneDay = 1000 * 60 * 60 * 24;
const oneMinute = 1000 * 60;

/**
 * Get day of the year (1-356)
 *
 * @param date
 * @param year
 *
 * @returns {number}
 */
export default function getDayOfYear (date, year = null) {
  const start = inUTC(year || date.getUTCFullYear(), 0, 1);
  const diff = date - start +
    // daylight savings time compensation
    (start.getTimezoneOffset() - date.getTimezoneOffset()) * oneMinute;
  return Math.floor(diff / oneDay) + 1;
}

/**
 * Get day of the year
 *
 * @param year
 * @param month (0-11)
 * @param day (1-31)
 * @return {number}
 */
export function getDayOfYearByYMD (year, month, day) {
  return getDayOfYear(inUTC(year, month, day));
}
