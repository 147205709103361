import Config from '../../config';

import { createReducer } from '@reduxjs/toolkit';
import { prepareUrl } from '../../utils/api-url-processor';
import { fetchActionSimplified } from '../../utils/fetch-action';

// actions

const namespace = 'ENTITIES/SITEMAPS';

const actionTypes = {
  SITEMAP_REQUEST: `${namespace}:REQUEST`,
  SITEMAP_RECEIVE: `${namespace}:RECEIVE`,
  SITEMAP_ERROR: `${namespace}:ERROR`
};

export const fetchSiteMap = fetchActionSimplified({
  getUrl: ({ url }) => prepareUrl(Config.api_url_sitemap, {
    url: encodeURIComponent(url)
  }),

  actions: [
    actionTypes.SITEMAP_REQUEST,
    actionTypes.SITEMAP_RECEIVE,
    actionTypes.SITEMAP_ERROR
  ]
});

function processSiteMap (action) {
  return {
    url: action.url,
    updatedAt: action.updatedAt,
    inProgress: action.inProgress,
    data: action.res
  };
}

export default createReducer({}, (builder) => {
  builder
    .addCase(actionTypes.SITEMAP_REQUEST, (draft, action) => {
      draft[action.url] = action;
    })
    .addCase(actionTypes.SITEMAP_ERROR, (draft, action) => {
      draft[action.url] = action;
    })
    .addCase(actionTypes.SITEMAP_RECEIVE, (draft, action) => {
      draft[action.url] = processSiteMap(action);
    });
});
