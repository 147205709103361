// Tool used for performance profiling. Please see README.md#performance
// before enabling it.
// import './wdyr';
import './main.less';

import './polyfill';

import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import createRavenMiddleware from 'raven-for-redux';
import React, { StrictMode } from 'react';

import AppRouter from './app-router';
import createRootReducer from './reducers';

const Raven = window.Raven;

// router
const history = createBrowserHistory();

// store
const initialState = {};

let middlewares = [];
// once we move to Raven >4
// here is example code for inspiration
// https://github.com/captbaritone/raven-for-redux/issues/93
if (Raven) {
  middlewares = middlewares.concat(
    createRavenMiddleware(Raven)
  );
}

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  initialState
});

// hot reloading
if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./reducers', async () => {
    console.info('update reducers');
    const createRootReducers = (await import('./reducers')).default;
    store.replaceReducer(createRootReducers());
  });
}

// start app
const Root = () => <StrictMode><AppRouter history={history} store={store}/></StrictMode>;
Root.displayName = 'Root';

/**
 * Force app router to open the same page.
 * It can be useful in case when we update config and router should show another feature
 */
export function refreshAppRouter () {
  history.push(history.location);
}

export default Root;
