const domainPattern = /^(?:(?:https?:\/\/?)|(?:ftp:\/\/))?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/i;

export function getDomainFromUri (uri) {
  const res = domainPattern.exec(uri);
  if (!res || res.length === 0) {
    return '';
  }
  return res[1];
}

// parser() has been moved to hocs/history-updater/

export function parseToTimestamp (str) {
  if (!str || str.length === 0) {
    return null;
  }

  const res = {
    year: Number.parseInt(str.substr(0, 4))
  };

  if (str.length < 6) {
    return res;
  }

  res.month = Number.parseInt(str.substr(4, 2));

  if (str.length < 8) {
    return res;
  }

  res.day = Number.parseInt(str.substr(6, 2));
  return res;
}

function twoDigits (num) {
  const str = num.toString();
  if (str.length === 1) {
    return `0${str}`;
  } else {
    return str;
  }
}

function dateToStr (date) {
  if (!Number.isFinite(date.year)) {
    return '';
  }
  let out = `${date.year}`;
  for (const item of [date.month, date.day, date.hour, date.minute, date.second]) {
    if (Number.isFinite(item) && item > 0) {
      out += `${twoDigits(item)}`;
    } else {
      break;
    }
  }
  return out.padEnd(14, '0');
}

export function timestampToStr (timestamp) {
  if (!timestamp) {
    return '*';
  }
  if (timestamp.equal) {
    return `${dateToStr(timestamp.equal)}*`;
  }
  if (timestamp.from && timestamp.until) {
    return `${dateToStr(timestamp.from)}-${dateToStr(timestamp.until)}*`;
  }
  return '*';
}
