const template = /(.*)\sfiletype\s*:\s*([a-zA-Z0-9_/]*)/;

/**
 * extract filetype and the rest query text
 *
 * @param text
 * @returns {{text: string, filetype: string} | {text: string}}
 */
export default function extractFileTextAndType (text) {
  const res = text.match(template);
  if (!res || res.length < 2) {
    return { text };
  }

  return {
    text: res[1],
    filetype: res[2]
  };
}
