/**
 * Create nested proxy which would call onSet handler for each prop se
 *
 * @param target
 * @param onSet
 * @returns {*}
 */
export function createNestedProxy (target, { onSet }) {
  const validator = {
    get (target, key) {
      if (typeof target[key] === 'object' && target[key] !== null) {
        return new Proxy(target[key], validator);
      } else {
        return target[key];
      }
    },

    set (target, key, value) {
      onSet(target, key, value);
      return Reflect.set(target, key, value);
    }
  };

  return new Proxy(target, validator);
}
