import React, { Suspense, useState } from 'react';

import Spinner from '../components/spinner/spinner';
import config from '../config';
import { getDisplayName } from '../utils/get-display-name';

const DefaultFallbackPanel = () => (<div className="text-center"><Spinner/></div>);

/**
 * Wrapper arounc lazy loading component
 * will show fallback component until component loading
 *
 * in debug mode we can manually toggle fallback and lazy component
 *
 * @param LazyComponent
 * @param FallbackComponent
 * @returns {function(*): *}
 */
export default (LazyComponent, FallbackComponent = DefaultFallbackPanel) => {
  const WaitingLazyComponent = props => {
    const [isVisible, show] = useState();
    return (
      <Suspense fallback={<FallbackComponent/>}>
        {config.debug && (
          <button
            onClick={() => show(!isVisible)}
            className="btn btn-xs btn-success">lazy
          </button>
        )}
        {
          config.debug && isVisible ? (<FallbackComponent/>) : (<LazyComponent {...props} />)
        }
      </Suspense>
    );
  };

  WaitingLazyComponent.displayName = `WaitingComponent(${getDisplayName(LazyComponent)})`;

  return WaitingLazyComponent;
};
