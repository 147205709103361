import { createReducer } from '@reduxjs/toolkit';
import { actionTypes as metadataActionTypes } from '../entities/metadata';

export const SUMMARY_SET_END_YEAR_FILTER = 'SUMMARY:SET_END_YEAR_FILTER';
export const SUMMARY_SET_MIME_TYPE_FILTER = 'SUMMARY:SET_MIME_TYPE_FILTER';
export const SUMMARY_SET_MIME_TYPE_EXACT = 'SUMMARY:SET_MIME_TYPE_EXACT';
export const SUMMARY_SET_START_YEAR_FILTER = 'SUMMARY:SET_START_YEAR_FILTER';
export const SUMMARY_SET_QUICK_SEARCH_FILTER = 'SUMMARY:SET_QUICK_SEARCH_FILTER';

export function setMimeTypeFilter (mimeType) {
  return {
    type: SUMMARY_SET_MIME_TYPE_FILTER,
    mimeType
  };
}

// set by dropdown list of mime types
export function setMimeTypeExact (mimeType) {
  return {
    type: SUMMARY_SET_MIME_TYPE_EXACT,
    mimeType
  };
}

export function setEndYearFilter (year) {
  return {
    type: SUMMARY_SET_END_YEAR_FILTER,
    year
  };
}

export function setStartYearFilter (year) {
  return {
    type: SUMMARY_SET_START_YEAR_FILTER,
    year
  };
}

export function setQuickSearchFilter (value) {
  return {
    type: SUMMARY_SET_QUICK_SEARCH_FILTER,
    value
  };
}

export default createReducer(
  {
    endYearFilter: null,
    mimeTypeFilter: null, // prefix value, e.g. image. Set by <span> button.
    mimeType: null, // exact value, e.g. image/png. Set by <select> dropdown.
    startYearFilter: null,
    quickSearchValue: null
  },
  (builder) => {
    builder
      .addCase(SUMMARY_SET_END_YEAR_FILTER, (draft, action) => {
        draft.endYearFilter = action.year;
      })
      .addCase(SUMMARY_SET_MIME_TYPE_FILTER, (draft, action) => {
        draft.mimeTypeFilter = action.mimeType;
      })
      .addCase(SUMMARY_SET_MIME_TYPE_EXACT, (draft, action) => {
        draft.mimeType = action.mimeType;
      })
      .addCase(SUMMARY_SET_START_YEAR_FILTER, (draft, action) => {
        draft.startYearFilter = action.year;
      })
      .addCase(SUMMARY_SET_QUICK_SEARCH_FILTER, (draft, action) => {
        draft.quickSearchValue = action.value;
      })
      .addCase(metadataActionTypes.METADATA_RECEIVED, (draft, action) => {
        draft.queryType = action.res.type;
      });
  }
);
