import './changes-heatmap-fallback.less';

import React, { memo } from 'react';

const ChangeHeatmapFallback = () => (
  <div className="changes-heatmap-fallback">
    <div className="changes-heatmap-fallback-note"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
    <div className="changes-heatmap-fallback-item-headline"/>
    <div className="changes-heatmap-fallback-container"/>
  </div>
);

ChangeHeatmapFallback.displayName = 'ChangeHeatmapFallback';

export default memo(ChangeHeatmapFallback);
