import { createAction, createReducer } from '@reduxjs/toolkit';

const namespace = 'UI/COLLECTIONS_CALENDAR';

const actionTypes = {
  COLLECTIONS_CALENDAR_NEXT_DAY: `${namespace}/NEXT_DAY`,
  COLLECTIONS_CALENDAR_PREVIOUS_DAY: `${namespace}/PREVIOUS_DAY`,
  COLLECTIONS_CALENDAR_HIGHLIGHT_COLLECTION_ID_AND_DAY_ID: `${namespace}/HIGHLIGHT_COLLECTION_ID_AND_TIMESTAMP_ID`,
  COLLECTIONS_CALENDAR_SCROLL_LEFT: `${namespace}/SCROLL_LEFT`,
  COLLECTIONS_CALENDAR_STICK_TO_LEFT: `${namespace}/STICK_TO_LEFT`,
  COLLECTIONS_CALENDAR_STICK_TO_TOP: `${namespace}/STICK_TO_TOP`,
  COLLECTIONS_CALENDAR_UNHIGHLIGHT_COLLECTION_AND_DAY: `${namespace}/UNHIGHLIGHT_COLLECTION_AND_DAY`
};

export const nextDay = createAction(actionTypes.COLLECTIONS_CALENDAR_NEXT_DAY);

export const previousDay = createAction(actionTypes.COLLECTIONS_CALENDAR_PREVIOUS_DAY);

export function highlightDayByYearAndCollection (collectionId, day, year) {
  return {
    type: actionTypes.COLLECTIONS_CALENDAR_HIGHLIGHT_COLLECTION_ID_AND_DAY_ID,
    payload: {
      collectionId,
      day,
      year
    }
  };
}

export function unhighlightCollectionAndTimestamp () {
  return {
    type: actionTypes.COLLECTIONS_CALENDAR_UNHIGHLIGHT_COLLECTION_AND_DAY
  };
}

export function leftStick (stick) {
  return {
    type: actionTypes.COLLECTIONS_CALENDAR_STICK_TO_LEFT,
    payload: { stick }
  };
}

/**
 * Scroll left collections calendar
 *
 * @param left {number}
 *
 * @returns {{type: string, left: *}}
 */
export function scrollLeft (left) {
  return {
    type: actionTypes.COLLECTIONS_CALENDAR_SCROLL_LEFT,
    payload: { left }
  };
}

export function topStick (stick) {
  return {
    type: actionTypes.COLLECTIONS_CALENDAR_STICK_TO_TOP,
    payload: { stick }
  };
}

const initialState = {
  highlighted: {
    collectionId: null,
    day: null
  },
  isLeftStick: false,
  isTopStick: false,
  scrollLeft: 0
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.COLLECTIONS_CALENDAR_NEXT_DAY, (draft) => {
      // TODO: how can we skip days without captures?
      // don't exceed the number of days in a year
      // and last day of the current year
      // TODO: rotate around once hit a limit
      draft.highlighted.day++;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_PREVIOUS_DAY, (draft) => {
      // TODO: how can we skip days without captures?
      // TODO: rotate around once hit a limit
      draft.highlighted.day--;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_HIGHLIGHT_COLLECTION_ID_AND_DAY_ID, (draft, { payload }) => {
      draft.highlighted.collectionId = payload.collectionId;
      draft.highlighted.day = payload.day;
      draft.highlighted.year = payload.year;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_UNHIGHLIGHT_COLLECTION_AND_DAY, (draft) => {
      draft.highlighted.year = null;
      draft.highlighted.collectionId = null;
      draft.highlighted.day = null;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_SCROLL_LEFT, (draft, action) => {
      draft.scrollLeft = action.payload.left;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_STICK_TO_LEFT, (draft, action) => {
      draft.isLeftStick = action.payload.stick;
    })
    .addCase(actionTypes.COLLECTIONS_CALENDAR_STICK_TO_TOP, (draft, action) => {
      draft.isTopStick = action.payload.stick;
    });
});
