import { scaleLog } from 'd3-scale';

// hi -> dark green
// export const heatmapPalette = ['#ffed7a', '#96C161', '#467386'];
// hi -> blue
export const heatmapPalette = ['#ffed7a', '#96C161', '#428bca'];

export const heatmapPaletteD3Color =
// linear
//   d3.scaleLinear()
// or log
  scaleLog()
    .domain([1.0, 1.5, 2.0])
    .range(heatmapPalette);

/**
 * Get color by distance and max possible distance
 *
 * @param value
 * @param maxValue
 * @returns {string}
 */
export function getColorByDistance (value, maxValue) {
  if (value === 0) {
    return nothingWasChangedColor;
  }
  return heatmapPaletteD3Color(1.0 + value / maxValue);
}

export const nothingWasChangedColor = '#ddd';
export const selections = ['#61c1b7', '#c17f61'];
