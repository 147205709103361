/**
 * Queue of lazy jobs
 * Job should return Promise and solves it once job is done.
 *
 */

import Config from '../../config';

import Queue from './queue';

export default new Queue({ numOfSimultaneousJobs: Config.fetch_queue.num_of_simultaneous_jobs });
