import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import set from 'lodash/set';
import { createReducer } from '@reduxjs/toolkit';
import { evaluateDiffDistance } from '../../components/changes-heatmap/evaluate-diff-distance';
import {
  colorHistogram,
  findMaxFieldValue,
  processHistogram,
  mapSimhashToCalendarAndEstimateVariation,
  numOfBins
} from '../../components/changes-heatmap/service';

import { NO_CAPTURES } from '../../services/changes/changes-fetch-errors';
import { ChangesFetchService } from '../../services/changes/changes-fetch-service';
import evaluationQueue from '../../services/changes/evaluation-queue';

import { getDiffByUrl, getDiffJobId } from '../../selectors/entities/diff';
import * as changesCalendarSelector from '../../selectors/ui/changes-calendar-selector';
import * as diffSelector from '../../selectors/ui/diff';
import * as searchRequestSelector from '../../selectors/ui/search-request';

import { sleep } from '../../utils/sleep';

const diffFetchService = new ChangesFetchService();

// actions
const namespace = 'ENTITIES/DIFF';
const actionTypes = {
  DIFF_CANCEL: `${namespace}/CANCEL`,
  DIFF_ERROR: `${namespace}/ERROR`,
  DIFF_PROGRESS: `${namespace}/PROGRESS`,
  DIFF_RECEIVED: `${namespace}/RECEIVED`,
  DIFF_CANCELED: `${namespace}/CANCELED`,
  DIFF_REQUEST: `${namespace}/REQUEST`,
  DIFF_EVAL_DISTANCE_START: `${namespace}/EVAL_DISTANCE_START`,
  DIFF_EVAL_DISTANCE_PROGRESS: `${namespace}/EVAL_DISTANCE_PROGRESS`,
  DIFF_EVAL_DISTANCE_FINISH: `${namespace}/EVAL_DISTANCE_FINISH`
};

export function cancelAllDiffFetchJobsOfCurrentUrl (url) {
  return (dispatch, getState) => {
    if (!url) {
      url = searchRequestSelector.getSubmittedQueryText(getState());
    }

    if (diffFetchService.stopAllOf({ url })) {
      dispatch({
        type: actionTypes.DIFF_CANCEL,
        payload: { url }
      });
    } else {
      console.warn('we do not have jobs for ', url);
    }
  };
}

export const pauseJobTracking = (year) => (dispatch, getState) => {
  const url = searchRequestSelector.getSubmittedQueryText(getState());
  diffFetchService.pauseJobTracking({ url, year });
};

export const resumeJobTracking = (year) => (dispatch, getState) => {
  const url = searchRequestSelector.getSubmittedQueryText(getState());
  diffFetchService.resumeJobTracking({ url, year });
};

export const validateData = ({ url, year, force }) => async (dispatch, getState) => {
  if (diffSelector.haveDiffDataForUrlAndYear(getState(), { url, year })) {
    // we have data and could evaluate distance from selected capture
    // TODO: don't evaluate when we already have it
    return dispatch(evalDistance({
      url,
      target: changesCalendarSelector.getTimestampA(getState()),
      year
    }));
  }

  if (!force && diffSelector.isDiffDataForUrlAndYearValid(getState(), { url, year })) {
    // exit in case (valid data):
    // - we are in progress data is fetching
    // - we got error on fetching data
    return;
  }

  // fetch data first
  return dispatch(fetchDiff({ url, year }));
};

/**
 * request url's diff (simhashes)
 *
 * @param {{url, year}}
 * @returns {function(*=): function(*)}
 */
export function fetchDiff (payload) {
  return async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DIFF_REQUEST,
      payload: {
        ...payload,
        updatedAt: Date.now()
      }
    });

    const jobId = getDiffJobId(getState(), payload);

    try {
      const res = await diffFetchService.start({ ...payload, jobId }, {
        onProgress: (progressType, progressData) => dispatch({
          type: actionTypes.DIFF_PROGRESS,
          payload: { ...payload, progressType, progressData }
        })
      });

      if (isAbortedResponse(res)) {
        return dispatch({
          type: actionTypes.DIFF_CANCELED,
          payload,
          metadata: {
            updatedAt: Date.now()
          }
        });
      }

      dispatch({
        type: actionTypes.DIFF_PROGRESS,
        payload: { ...payload, progressType: 'PROCESSING', progressData: { info: 'in queue...' } }
      });

      const data = await evaluationQueue.add(
        async () => processDiffResponse({
          ...payload,
          res,
          onProgress: ({ index, total }) => dispatch({
            type: actionTypes.DIFF_PROGRESS,
            payload: {
              ...payload,
              progressType: 'PROCESSING',
              progressData: {
                info: `evaluating variance ${Math.round(100 * index / total)}%`,
                index,
                total
              }
            }
          })
        })
      );

      dispatch({
        type: actionTypes.DIFF_RECEIVED,
        payload: {
          ...payload,
          updatedAt: Date.now(),
          data
        }
      });
    } catch (error) {
      if (window.Raven) {
        window.Raven.captureException(error, { extra: { payload } });
      }

      dispatch({
        type: actionTypes.DIFF_ERROR,
        payload: {
          ...payload,
          updatedAt: Date.now(),
          error: error && error.message ? error.message : error
        }
      });
    }
  };
}

function isAbortedResponse (res) {
  return res === null;
}

export const evalDistance = ({ year, url, target }) => async (dispatch, getState) => {
  if (diffSelector.isInProgressDistanceEvaluation(getState(), { year, url })) {
    return;
  }

  dispatch({
    type: actionTypes.DIFF_EVAL_DISTANCE_START,
    payload: {
      target,
      url,
      year
    },
    metadata: {
      updatedAt: Date.now()
    }
  });

  dispatch({
    type: actionTypes.DIFF_EVAL_DISTANCE_PROGRESS,
    payload: {
      target,
      url,
      progressType: 'PROCESSING',
      progressData: {
        info: 'evaluating distance ... in queue'
      },
      year
    }
  });

  const data = await evaluationQueue.add(
    async () => {
      const diff = getDiffByUrl(getState(), { url });
      return evaluateDiffDistance(diff, target, year, {
        onProgress: ({ index, total }) => {
          dispatch({
            type: actionTypes.DIFF_EVAL_DISTANCE_PROGRESS,
            payload: {
              target,
              url,
              progressType: 'PROCESSING',
              progressData: {
                info: `evaluating distance ${Math.round(100 * index / total)}%`,
                index,
                total
              },
              year
            }
          });
        }
      });
    }
  );

  dispatch({
    type: actionTypes.DIFF_EVAL_DISTANCE_FINISH,
    payload: {
      data,
      target,
      url,
      year
    },
    metadata: {
      updatedAt: Date.now()
    }
  });
};

// reducers

/**
 * Process simhash response
 *
 * - map simhash list to calendar
 * - find histogram of value
 *
 * @param res
 * @param url
 * @param year
 * @returns {Promise<{total: *, calendar: void, histogram: (*|*[]), maxValue: number}>}
 */
async function processDiffResponse ({ res, url, year, delay = 20, onProgress }) {
  const total = res.length;
  const calendar = await mapSimhashToCalendarAndEstimateVariation({ data: res, url, year, delay, onProgress });

  await sleep(delay);

  const maxValue = Math.max(numOfBins, findMaxFieldValue(calendar));

  let histogram = processHistogram({
    data: calendar,
    maxValue,
    size: 10
  });

  await sleep(delay);

  histogram = colorHistogram({ histogram, maxValue });
  return {
    total,
    calendar,
    histogram,
    maxValue
  };
}

function deepObjectSet (o, path, value) {
  const fieldName = path.shift();
  if (path.length > 0) {
    if (!(fieldName in o)) {
      o[fieldName] = {};
    }
    deepObjectSet(o[fieldName], path, value);
  } else {
    o[fieldName] = value;
  }
}

function setStateOfUrlAndYear (draft, url, year, value, prefix = []) {
  if (!draft[url]) {
    draft[url] = {};
  }

  if (prefix.length > 0) {
    // FIXME: how it is just hardcoded values
    draft = draft[prefix[0]];
  }

  if (!draft[url][year]) {
    draft[url][year] = {};
  }

  draft[url][year] = { ...draft[url][year], ...value };
}

const initialState = {};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.DIFF_REQUEST, (draft, { payload }) =>
      setStateOfUrlAndYear(
        draft, payload.url, payload.year, {
          inProgress: true,
          updatedAt: payload.updatedAt
        }
      )
    )
    .addCase(actionTypes.DIFF_PROGRESS, (draft, { payload: { url, year, progressType, progressData } }) => {
      if (
        isEqual(get(draft, [url, year, 'progressData']), progressData) &&
        isEqual(get(draft, [url, year, 'progressType']), progressType)
      ) {
        return;
      }

      setStateOfUrlAndYear(
        draft, url, year, { progressType, progressData }
      );
    })
    .addCase(actionTypes.DIFF_ERROR, (draft, { payload }) => {
      let error;
      let allowRetry;

      if (payload.error === NO_CAPTURES) {
        error = 'No archives for this year';
        allowRetry = false;
      } else {
        error = payload.error;
        allowRetry = true;
      }

      setStateOfUrlAndYear(
        draft, payload.url, payload.year, {
          inProgress: false,
          updatedAt: payload.updatedAt,
          allowRetry,
          error
        }
      );
    })
    .addCase(actionTypes.DIFF_CANCELED, (draft, { payload, metadata }) =>
      deepObjectSet(draft, [payload.url, payload.year], {
        inProgress: false,
        updatedAt: metadata.updatedAt
      })
    )
    .addCase(actionTypes.DIFF_RECEIVED, (draft, { payload }) =>
      setStateOfUrlAndYear(
        draft, payload.url, payload.year, {
          inProgress: false,
          progressData: null,
          progressType: null,
          error: null,
          data: payload.data,
          updatedAt: payload.updatedAt
        }
      )
    )
    .addCase(actionTypes.DIFF_EVAL_DISTANCE_START, (draft, { metadata, payload: { url, target, year } }) => {
      // TODO: we can store more evaluations. But we should also rotate them and remove old
      // clear previous target timestamp
      const distanceTo = get(draft, [url, 'distanceTo']);

      if (distanceTo) {
        Object.keys(distanceTo)
          .filter(oneTarget => oneTarget !== String(target))
          .forEach(oneTarget => {
            set(draft, [url, 'distanceTo', oneTarget], {});
          });
      }

      deepObjectSet(draft, [url, 'distanceTo', target, year], {
        inProgress: true,
        updatedAt: metadata.updatedAt
      });
    })
    .addCase(actionTypes.DIFF_EVAL_DISTANCE_PROGRESS, (draft, { metadata, payload: { url, target, year, progressType, progressData } }) => {
      deepObjectSet(draft, [url, 'distanceTo', target, year, 'progressData'], progressData);
      deepObjectSet(draft, [url, 'distanceTo', target, year, 'progressType'], progressType);
    })
    .addCase(actionTypes.DIFF_EVAL_DISTANCE_FINISH, (draft, { metadata, payload: { data, url, target, year } }) => {
      deepObjectSet(draft, [url, 'distanceTo', target, year], {
        inProgress: false,
        data
      });
    });
});
