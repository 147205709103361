class DropException extends Error {
}

/**
 * abandon previous promises and
 * wait msecGlobal before solve new promise
 *
 * @param msecGlobal
 * @returns {function()}
 */
export function debouncePromise (msecGlobal = 0) {
  let timerId = null;
  let previousResolve = null;
  let previousReject = null;

  const res = (msec = null) => {
    if (msec === null) {
      msec = msecGlobal;
    }

    res.cancel();

    return new Promise((resolve, reject) => {
      previousResolve = resolve;
      previousReject = reject;
      timerId = setTimeout(() => {
        previousResolve && previousResolve();
        previousResolve = null;
        previousReject = null;
        timerId = null;
      }, msec);
    });
  };

  res.cancel = () => {
    previousResolve = null;
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
    if (previousReject) {
      previousReject(new DropException());
      previousReject = null;
    }
  };

  return res;
}

/**
 * Drop promise sequence
 *
 * @returns {*}
 */
export function dropPromise () {
  return Promise.reject(new DropException());
}

/**
 * call handler if error isn't DropExpection
 *
 * @param handler
 * @returns {Function}
 */
export function ifNotDropped (handler) {
  return (res) => {
    if (!(res instanceof DropException)) {
      handler(res);
    }
  };
}

/**
 * Throw any error which is not just dropped request
 *
 * @returns {Function}
 */
export function ignoreDropped () {
  return ifNotDropped((err) => {
    throw err;
  });
}
