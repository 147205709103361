import PropTypes from 'prop-types';
import React from 'react';

import ErrorPanel from './error-panel';

const ErrorAndRetry = ({ error, onRetry }) => (
   <ErrorPanel title="Hrm.">
     <p>{error}</p>
     <button type="button" className="btn btn-primary" onClick={onRetry}>Retry</button>
  </ErrorPanel>
);

ErrorAndRetry.displayName = 'ErrorAndRetry';

ErrorAndRetry.propTypes = {
  error: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired
};

export default ErrorAndRetry;
