import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

export const getCaptures = state => state.entities.captures;

export const getUrl = (state, props) => props.url;

export const getYear = (state, props) => props.year;

export const getMonth = (state, props) => props.month;

// getUrl return None for unknown reason when using this selector in `collections-layout.js`.
export const getCapturesPerYear = createSelector(
  [getCaptures],
  (captures) => {
    const url = Object.keys(captures)[0];
    return get(captures, [url], []);
  }
);

export const isCapturesDistributionInProgress = createSelector(
  [getCaptures, getUrl],
  (captures, url) => get(captures, [url, 'distribution', 'inProgress'], false)
);

export const isCapturesByYearInProgress = createSelector(
  [getCaptures, getUrl, getYear],
  (captures, url, year) => get(captures, [url, 'byYear', year, 'inProgress'], false)
);

export const isCapturesByMonthInProgress = createSelector(
  [getCaptures, getUrl, getYear, getMonth],
  (captures, url, year, month) => get(captures, [url, 'byMonth', year, month, 'inProgress'], false)
);

export const isCapturesCollectionsInProgress = createSelector(
  [getCaptures, getUrl],
  (captures, url) => get(captures, [url, 'byCapture', 'inProgress'], false)
);

export const isUrlLive = createSelector(
  [getCaptures, getUrl],
  (captures, url) => get(captures, [url, 'isLive'], false)
);
