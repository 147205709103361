import { getDayOfYearByYMD } from '../../utils/datetime/day-of-year';

import dayOfYearToDate from './day-of-year-to-date';

export default function getNextDay (year, month, day) {
  const dayIdx = getDayOfYearByYMD(year, month, day);
  const newDate = dayOfYearToDate(year, dayIdx - 1);
  return {
    month: newDate.getUTCMonth(),
    day: newDate.getUTCDate()
  };
};
