import { createAction, createReducer } from '@reduxjs/toolkit';

const namespace = 'UI/SPARKLINE';

const actionTypes = {
  HIGHLIGHT: `${namespace}/HIGHLIGHT`
};

export const highlight = createAction(actionTypes.HIGHLIGHT);

const initialState = { highlighted: {} };

export default createReducer(initialState, (builder) => {
  builder.addCase(actionTypes.HIGHLIGHT, (draft, { payload }) => {
    draft.highlighted = payload ? { ...draft.highlighted, ...payload } : {};
  });
});
