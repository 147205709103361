import PropTypes from 'prop-types';
import React from 'react';

import Config from '../../config';

import ErrorPanel from './error-panel';

import { connect } from 'react-redux';

import { isUrlLive } from '../../selectors/entities/captures';

// strip parameters and one level of path component from url (if exists).
function dirURL (url) {
  // deal with URL without protocol, like example.com/index.html, or
  // //example.com/index.html
  if (url.startsWith('//')) {
    url = 'http:' + url;
  }
  try {
    url = new URL(url);
  } catch (ex) {
    try {
      url = new URL('http:' + url);
    } catch (ex) {
      return url;
    }
  }
  // note URL changes empty pathname to '/'. So for the consistent result
  // we need to retain trailing slash, and url_search_page URL template
  // should not add a slash before "*"
  url.pathname.replace(/\/[^/]+$/, '/');
  url.search = url.hash = '';
  return url.toString();
}

const NotArchived = ({ isLive, url }) => {
  const durl = dirURL(url);
  return (
  <ErrorPanel title="Hrm.">
    <p>Wayback Machine has not archived that URL.</p>
    {
      isLive && (
        <div id="livewebInfo" className="available">
          <h2 className="blue">
            This page is available on the web!
          </h2>
          <p>
            Help make the Wayback Machine more complete!
          </p>
    <form name="spn-form" id="spn-form" action="/save" method="POST">
      <p>
        <b>
          <button className="btn btn-default">Save this URL in the Wayback Machine</button>
          <input name="url_preload" type="hidden" value={url} />
        </b>
      </p>
    </form>
        </div>
      )
    }
    <p>Click here to search for all archived pages under <a
      href={Config.url_search_page.replace('{{url}}', durl)}>{durl}</a></p>
  </ErrorPanel>
  );
};

NotArchived.displayName = 'NotArchived';
NotArchived.propTypes = {
  isLive: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default connect(
  (state, props) => ({
    isLive: isUrlLive(state, props)
  })
)(React.memo(NotArchived));
