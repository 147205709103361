import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import WaybackMachineLogo from './wayback-machine-logo';

const WaybackMachineLogoWithLink = () => (
  <Link to={'/'}><WaybackMachineLogo/></Link>
);

WaybackMachineLogoWithLink.displayName = 'WaybackMachineLogoWithLink';

export default memo(WaybackMachineLogoWithLink);
