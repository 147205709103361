import PropTypes from 'prop-types';
import React from 'react';

export function HideWhen(props) {
  const { condition, component, children } = props;
  return condition ? null : component ? React.createElement(component, props)
    : children ? children(props) : null;
}

HideWhen.propTypes = {
  condition: PropTypes.bool,
  component: PropTypes.elementType
};
