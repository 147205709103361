/**
 * Extract statuses from year captures
 *
 * @param byDay
 * @return {any}
 */
export function extractStatusStatsOfYear (byDay) {
  return Object.values(byDay || {})
    .reduce((acc, { st, cn }) => {
      let key;
      if (st === '-') {
        key = '-';
      } else {
        key = `${Math.round(st / 100)}xx`;
      }
      acc[key] += cn;
      return acc;
    }, { '2xx': 0, '3xx': 0, '4xx': 0, '5xx': 0, '-': 0 });
}
