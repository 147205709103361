import inUTC from './in-utc';

/**
 * Convert day of an year (and the year) to Date
 *
 * @param year
 * @param dayOfYear (1-356)
 *
 * @return {Date}
 */
export default function dayOfYearToDate (year, dayOfYear) {
  return inUTC(year, 0, dayOfYear);
}
