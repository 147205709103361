import { createSelector } from '@reduxjs/toolkit';

import { getDayOfYearByYMD } from '../../utils/datetime/day-of-year';

const getSparkline = state => state.ui.sparkline;

const getHighlightedRaw = createSelector(
  [getSparkline],
  (sparkline) => sparkline.highlighted
);

export const getHighlighted = createSelector(
  [getHighlightedRaw],
  (highlighted) => (highlighted && {
    ...highlighted,
    dayOfYear: getDayOfYearByYMD(highlighted.year, highlighted.month, highlighted.day)
  })
);
