import './calendar-fallback.less';

import React, { memo } from 'react';

const CalendarFallback = () => (
  <div className="calendar-fallback">
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
    <div className="calendar-month-fallback"/>
  </div>
);

CalendarFallback.displayName = 'CalendarFallback';

export default memo(CalendarFallback);
