/**
 * @private
 *
 * get week of the month
 *
 * @param date
 * @returns {*}
 */
function getWeekOfMonth (date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstWeekday = new Date(year, month, 1).getDay();
  const offsetDate = date.getDate() + firstWeekday - 1;
  return Math.floor(offsetDate / 7);
}

/**
 * Map distribution of captures to the calendar object with structure [month, week, day of week]
 *
 * We don't use UTC time here because we just use Date object to iterate over (month, day)
 * and extract week number in a month.
 * That's why time zone or day saving time don't bother us.
 *
 * TODO: shouldn't we start week from Monday or Sunday depends on local?
 *
 * @param items
 * @param year
 * @return {any[]}
 */
export function mapCapturesDistributionToCalendar (items = {}, year) {
  const calendar = new Array(12);

  for (let dateCursor = new Date(year, 0, 1); dateCursor.getFullYear() <= year; dateCursor.setDate(dateCursor.getDate() + 1)) {
    const month = dateCursor.getMonth();
    const week = getWeekOfMonth(dateCursor);
    const day = dateCursor.getDay();
    const date = dateCursor.getDate().toString();
    if (!calendar[month]) {
      calendar[month] = [];
    }
    if (!calendar[month][week]) {
      calendar[month][week] = new Array(7).fill(null);
    }
    const key = `${month + 1}${date.padStart(2, '0')}`;
    const item = items[key];
    if (item) {
      calendar[month][week][day] = item;
    } else {
      calendar[month][week][day] = {};
    }
  }
  return calendar;
}

export function mapMonthlyCapturesDistributionToCalendar (monthData = {}, year, month) {
  const calendarMonth = [];

  for (
    let dateCursor = new Date(year, month, 1);
    dateCursor.getMonth() <= month && dateCursor.getFullYear() <= year;
    dateCursor.setDate(dateCursor.getDate() + 1)
  ) {
    const week = getWeekOfMonth(dateCursor);
    const weekDay = dateCursor.getDay();
    const date = dateCursor.getDate().toString();
    if (!calendarMonth[week]) {
      calendarMonth[week] = new Array(7).fill(null);
    }
    const item = monthData[date];
    if (item) {
      calendarMonth[week][weekDay] = item;
    } else {
      calendarMonth[week][weekDay] = {};
    }
  }

  return calendarMonth;
}
