import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

export const getDiff = state => state.entities.diff;

export const getPropUrl = (state, props) => props.url;

export const getDiffByUrl = createSelector(
  [getDiff, getPropUrl],
  (diff, url) => diff[url]
);

export const getDiffStatePath = (state, props) => [props.url, props.year];

export const getDiffJobId = createSelector(
  [getDiff, getDiffStatePath],
  (diff, statePath) => get(diff, statePath.concat(['progressData', 'jobId']))
);
