/**
 * if query of user is valid url
 *  we will show a calendar
 * otherwise
 *  it will be just plain search
 */
import { connect } from 'react-redux';

import SearchResultOrUrlDetails from './search-result-or-url-details';
import { fetchSuggestionsForSubmittedQuery } from '../../reducers/entities/hosts';
import { hasUrlCapturesDistribution } from '../../selectors/ui/calendar';
import { getError, isQueryUrl, isQueryUrlValid } from '../../selectors/ui/hosts';
import { getSubmittedQueryTypesAndText } from '../../selectors/ui/search-request';

const SearchResultOrUrlDetailsContainer = connect(
  (state, props) => ({
    hasUrlCapturesDistribution: hasUrlCapturesDistribution(state, props),
    isUrl: isQueryUrl(state, props),
    isUrlValid: isQueryUrlValid(state, props),
    query: getSubmittedQueryTypesAndText(state, props),
    urlFetchingError: getError(state, props)
  }),

  (dispatch, props) => ({
    onRetryIsUrl: () => dispatch(fetchSuggestionsForSubmittedQuery())
  })
)(SearchResultOrUrlDetails);

export {
  SearchResultOrUrlDetailsContainer
};
