import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const Spinner = ({ size = 3 }) => {
  return (
    <FontAwesomeIcon icon={faSpinner} size={`${size}x`} pulse/>
  );
};

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
  size: PropTypes.number
};

export default React.memo(Spinner);
