import PropTypes from 'prop-types';
import React from 'react';
import ErrorPanel from './error-panel';

const NoCollectionInfoForOneYear = ({ url, year }) => (
  <ErrorPanel title={`No collection information available for ${year}.`}>
    <p>The Wayback Machine doesn&apos;t have any collection information available for {year}.</p>
    <p>Do you want to list all archived URLs with this prefix: <a href={`/web/*/${url}/*`}>{url}</a>?</p>
  </ErrorPanel>
);

NoCollectionInfoForOneYear.displayName = 'NotArchivedForOneYear';
NoCollectionInfoForOneYear.propTypes = {
  url: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
};

export default React.memo(NoCollectionInfoForOneYear);
