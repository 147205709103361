// encode Object params into form-urlencoded string.
// currently values are not escaped for backward compatibility.
// it'd be more convenient for this function to take care of it.
function encodeParams (params) {
  return Object.entries(params).map(
    ([k, v]) => k + '=' + v // encodeURIComponent(v).replace('%20', '+')
  ).join('&');
}

/**
 * @private
 * @param template
 * @param config
 * @returns {string}
 */
function processUrlTemplate (template, config) {
  const paramsRegex = /{{([a-zA-Z0-9$_]+)}}/g;
  const segments = [];
  let last = 0;
  let match;
  while ((match = paramsRegex.exec(template)) !== null) {
    if (match.index > last) {
      segments.push(template.substring(last, match.index));
    }
    const paramValue = config[match[1]];
    if (paramValue) {
      if (typeof paramValue === 'object') {
        segments.push(encodeParams(paramValue));
      } else {
        segments.push(paramValue);
      }
    }
    last = paramsRegex.lastIndex;
  }
  if (last < template.length) {
    segments.push(template.substring(last));
  }
  return segments.join('');
}

/**
 * process api endpoint url to valid url with config values
 *
 * @param template
 * @param config
 * @returns {*}
 */
export function prepareUrl (template, config) {
  if (typeof template === 'string') {
    return processUrlTemplate(template, config);
  } else {
    return {
      crossDomain: template.cross_domain,
      url: processUrlTemplate(template.url, config)
    };
  }
}
