import PropTypes from 'prop-types';
import React from 'react';
import ErrorPanel from './error-panel';

const NotArchivedForOneYear = ({ url, year }) => (
  <ErrorPanel title={`${year} wasn't archived.`}>
    <p>The Wayback Machine doesn&apos;t have that page archived in {year}.</p>
    <p>Do you want to list all archived URLs with this prefix: <a href={`/web/*/${url}/*`}>{url}</a>?</p>
  </ErrorPanel>
);

NotArchivedForOneYear.displayName = 'NotArchivedForOneYear';
NotArchivedForOneYear.propTypes = {
  url: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
};

export default React.memo(NotArchivedForOneYear);
