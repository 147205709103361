/**
 * Higher Order Component (HOC) of synchronizing search state with browser history
 *
 * - Update browser history when search query text was changed
 * or selected year.
 *
 * - And update search query text and selected year
 * when browser history was changed
 */
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Config from '../config';
import {
  selectCalendarDate
} from '../reducers/ui/calendar';
import {
  updateSearchQuery, updateSearchInputFieldText
} from '../reducers/ui/search-request';
import { selectView } from '../reducers/ui/search-result';
import * as calendarSelector from '../selectors/ui/calendar';
import * as searchRequestSelector from '../selectors/ui/search-request';
import { prepareUrl } from '../utils/api-url-processor';
import { debouncePromise, ignoreDropped } from '../utils/debounce-promise';
import { getDisplayName } from '../utils/get-display-name';
import {
  getDomainFromUri, parseToTimestamp, timestampToStr
} from '../utils/timestamp-url-parser';
import reduce from 'lodash/reduce';

const pattern = /(\d*)(-?)(\d*)\*?/;

/**
 * Default archival URL parser for Capture Query (calendar)
 * note actual parsing is done by react-router with /web/([^/]+)/(.+)'
 * path pattern. splat is bound to react-router prop match.params, which
 * is an object with those unnamed captures by position (int) as key. i.e.,
 * the first capture ([^/]+) is splat[0], and the second capture (.+) is
 * splat[1].
 * So this function is essentially guessing which route was chosen by looking at
 * match.params, and giving names "url", "domain" and "timestamp" to positional
 * parameters react-router extracted from the path. This is very fragile and makes
 * historyUpdater a messy cross-point of different routes. We need to generalize
 * historyUpdater by moving these route-specific logic to each sub-app.
 */
function parser (splat, querysearch = '') {
  // parser passes react-router match.param to splat. currently there's no
  // known case where splat is a String.
  if (typeof splat === 'string') {
    throw new Error(`parser: unexpected String value for splat: ${splat}`);
    // // fix for http:/example.com
    // splat = splat.replace(/^(https?|ftp):\/(?!\/)/i, '$&/');
    // return {
    //   url: splat + querysearch,
    //   domain: getDomainFromUri(splat),
    //   timestamp: null
    // };
  }

  if (typeof splat === 'object') { // always true
    // convert Object to Array - unnamed captures become regular elements
    // of the Array. named parameters become properties of the Array
    // (not cool, but harmless)
    splat = reduce(splat, (res, value, key) => {
      res[key] = value;
      return res;
    }, []);
  }

  if (!splat || splat.length === 0) {
    return { url: '', domain: '', timestamp: null };
  }

  if (splat.length === 1) {
    // route /web/\*/([^*]+)\*' -- splat[0] is the target_url (sans query part)
    // fix for http:/example.com
    splat[0] = splat[0].replace(/^(https?|ftp):\/(?!\/)/i, '$&/');
    return {
      url: splat[0] + querysearch,
      domain: getDomainFromUri(splat[0]),
      timestamp: null
    };
  }

  const fistSplat = splat[0];
  const lastSplat = splat[splat.length - 1];

  const res = pattern.exec(fistSplat);
  const domain = getDomainFromUri(lastSplat);
  const url = lastSplat + querysearch;

  const fromTimeStamp = parseToTimestamp(res[1]);
  const untilTimeStamp = parseToTimestamp(res[3]);

  let timestamp = null;
  if (untilTimeStamp && fromTimeStamp) {
    timestamp = { from: fromTimeStamp, until: untilTimeStamp };
  } else if (fromTimeStamp) {
    timestamp = { equal: fromTimeStamp };
  }
  return { url, domain, timestamp };
}

let cachedKey = { queryString: '', splat: null };
let cachedValue = parser(null, '');

/**
 * memorization for url parser
 *
 * @param splat
 * @param queryString
 * @returns {{url, timestamp}}
 */
function getTimestampAndUrlBySplat (splat, queryString = '') {
  if (splat === cachedKey.splat && queryString === cachedKey.queryString) {
    return cachedValue;
  }

  cachedKey = { splat, queryString };
  cachedValue = parser(splat, queryString);
  return cachedValue;
}

/**
 * @param id, id of view
 * @param template, used to update browser history
 * @param delay, delay before update
 * @returns {function(*=)}
 */
export function historyUpdater ({ id, template, delay = Config.default_debounce_delay }) {
  if (!template) {
    throw new Error('template is required');
  }

  let templateFn;
  if (typeof template === 'function') {
    templateFn = template;
  } else {
    templateFn = (props) => prepareUrl(template, props);
  }

  return (WrappedComponent) => {
    class HistoryUpdater extends React.Component {
      static displayName = `HistoryUpdater(${getDisplayName(WrappedComponent)})`;

      static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object,

        calendarSelection: PropTypes.object,
        rawQuery: PropTypes.string,
        queryText: PropTypes.string,
        queryTypes: PropTypes.string,

        onSelectCalendarDate: PropTypes.func,
        selectView: PropTypes.func,
        updateSearchText: PropTypes.func,
        updateSearchInputFieldText: PropTypes.func
      };

      constructor (props) {
        super(props);

        this._debounceUpdateRouter = debouncePromise(delay);
        this.updateSearchRequestFromRouterParamsIfNeeded(this.props);
      }

      componentDidMount () {
        this.props.selectView(id);
      }

      shouldComponentUpdate (nextProps) {
        return !isEqual(nextProps, this.props);
      }

      _hasQueryChanged (prevProps) {
        return this.getText() !== this.getText(prevProps) ||
               this.props.queryTypes !== prevProps.queryTypes ||
               this.getTimestamp() !== this.getTimestamp(prevProps)
      }

      componentDidUpdate (prevProps) {
        if (!isEqual(prevProps.match.params, this.props.match.params)) {
          // NOTE: this.props.location.search is not ready yet
          // we should wait a moment before use
          setTimeout(() => {
            this.updateSearchRequestFromRouterParamsIfNeeded(this.props);
          }, 0);
        }

        if (this._hasQueryChanged(prevProps)) {
          this._debounceUpdateRouter()
            .then(() => {
              const text = this.getText();
              const timestamp = this.getTimestamp();
              const collection = this.props.match?.params.collection;
              let newPath;
              if (text === '' && isEmpty(collection)) {
                newPath = '/';
              } else {
                newPath = templateFn({
                  ...this.props,
                  text,
                  timestamp,
                  collection
                });
              }

              if (newPath !== this.getPathname()) {
                this.props.history.push(newPath);
              }
            })
            .catch(ignoreDropped());
        }
      }

      updateSearchRequestFromRouterParamsIfNeeded ({ match, location, rawQuery }) {
        let { url, timestamp } = getTimestampAndUrlBySplat(
          match.params, location && location.search
        );
        // note: this is critical to keep the URL hash when changing between
        // Calendar, Collections, Changes etc and calendar years.
        url += window.location.hash;
        if (url !== rawQuery) {
          this.props.updateSearchText(url, match.params.collection);
          this.props.updateSearchInputFieldText(url);
        }
        // special case: set collection when loading /<collection>/search URL.
        if (url === '' && rawQuery === '' && match.params.collection !== '') {
          this.props.updateSearchText(url, match.params.collection);
        }

        if (timestamp) {
          if (timestamp.equal) {
            this.props.onSelectCalendarDate(timestamp.equal);
          }

          if (timestamp.from) {
            this.props.onSelectCalendarDate(timestamp.from);
          }
        }
      }

      getPathname () {
        return this.props.location.pathname;
      }

      getText (props = this.props) {
        return Config.search.immediate ? props.immediateText : props.queryText;
      }

      getTimestamp (props = this.props) {
        return props.calendarSelection ? timestampToStr({ equal: props.calendarSelection }) : '*';
      }

      render () {
        return <WrappedComponent {...this.props}/>;
      }
    }

    return connect(
      (state, props) => ({
        ...props,
        calendarSelection: calendarSelector.getSelected(state, props),
        rawQuery: searchRequestSelector.getSubmittedRawQuery(state, props),
        queryText: searchRequestSelector.getSubmittedQueryText(state, props),
        queryTypes: searchRequestSelector.getSubmittedQueryTypes(state, props)
      }),
      (dispatch) => ({
        onSelectCalendarDate: date => dispatch(selectCalendarDate(date)),
        selectView: view => dispatch(selectView(view)),
        updateSearchText: (query, collection) => dispatch(updateSearchQuery(query, collection)),
        updateSearchInputFieldText: url => dispatch(updateSearchInputFieldText(url))
      })
    )(HistoryUpdater);
  };
}
