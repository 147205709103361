import {
  combineReducers
} from 'redux';

import calendar from './calendar';
import changesCalendar from './changes-calendar';
import collectionsCalendar from './collections-calendar';
import searchRequest from './search-request';
import searchResult from './search-result';
import sparkline from './sparkline';
import summary from './summary';

export default combineReducers({
  calendar,
  changesCalendar,
  collectionsCalendar,
  searchRequest,
  searchResult,
  sparkline,
  summary
});
