export const types = {
  DOMAIN: 'domain',
  HOST: 'host',
  TLD: 'tld',
  URL: 'url',
  WAYBACK_SUMMARY: 'waybacksummary'
};

/**
 * extract explicit defined query type
 *
 * @param query
 * @returns {string|null}
 */
export function extractExplicitQueryType (query) {
  if (query.indexOf('domain:') === 0) {
    return types.DOMAIN;
  } else if (query.indexOf('tld:') === 0) {
    return types.TLD;
  } else if (query.indexOf('host:') === 0) {
    return types.HOST;
  } else if (query === 'waybacksummary') {
    return types.WAYBACK_SUMMARY;
  }

  return null;
}
