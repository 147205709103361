import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from '@reduxjs/toolkit';

import * as searchRequestSelector from './search-request';
import { getTimestampA } from './changes-calendar-selector';

import { getDiff } from '../entities/diff';

import getProps from './props';

export const getCurrentDiff = createSelector(
  [getDiff, searchRequestSelector.getSubmittedQueryText],
  (diff, queryText) => diff[queryText]
);

export const haveDiffDataForUrlAndYear = createSelector(
  [getDiff, getProps],
  (diff, { url, year }) => has(diff, [url, year, 'data'])
);

/**
 * we have data, fetching it, or we got error
 */
export const isDiffDataForUrlAndYearValid = createSelector(
  [getDiff, getProps],
  (diff, { url, year }) => has(diff, [url, year, 'data']) ||
    get(diff, [url, year, 'inProgress']) ||
    has(diff, [url, year, 'error'])
);

export const isInProgressDistanceEvaluation = createSelector(
  [getDiff, getTimestampA, getProps],
  (diff, timestampA, { url, year }) => get(diff, [url, 'distanceTo', timestampA, year, 'inProgress'])
);

export const isDiffDataValid = createSelector(
  [getDiff, getTimestampA, getProps],
  (diff, timestampA, { url, year }) => {
    let data;
    if (timestampA) {
      data = get(diff, [url, 'distanceTo', timestampA]);
    } else {
      data = diff[url];
    }
    return !isEmpty(get(data, [year, 'data'])) && !get(data, [year, 'inProgress']);
  }
);

export const getDiffDataOrError = createSelector(
  [getDiff, getTimestampA, getProps],
  (diff, timestampA, { url, year }) => {
    if (timestampA) {
      const data = get(diff, [url, 'distanceTo', timestampA, year]);
      if (data) {
        return data;
      }
    }

    return get(diff, [url, year]);
  }
);

export const getCurrentInteractiveDiff = createSelector(
  [getCurrentDiff, getTimestampA],
  (diffOfUrl, timestampA) => {
    const data = get(diffOfUrl, ['distanceTo', timestampA]);
    if (timestampA && data) {
      return data;
    }

    return diffOfUrl;
  }
);
