/**
 * Split timestamp by years, months, days, hours, minutes and seconds
 *
 * @param timestamp
 * @returns {*[]}
 * @private
 */
export default function splitTimestamp (timestamp) {
  if (typeof timestamp === 'number') {
    timestamp = timestamp.toString();
  }

  return [
    // year
    timestamp.slice(-14, -10),
    // month
    timestamp.slice(-10, -8),
    // day
    timestamp.slice(-8, -6),
    // hour
    timestamp.slice(-6, -4),
    // minute
    timestamp.slice(-4, -2),
    // seconds
    timestamp.slice(-2)
  ];
}
