/**
 * Works with metadata about hosts
 */
import { createReducer } from '@reduxjs/toolkit';
import Config from '../../config';

import { prepareUrl } from '../../utils/api-url-processor';
import { fetchActionSimplified } from '../../utils/fetch-action';

const namespace = 'ENTITIES/METADATA';

export const actionTypes = {
  METADATA_REQUEST: `${namespace}/REQUEST`,
  METADATA_RECEIVED: `${namespace}/RECEIVED`,
  METADATA_ERROR: `${namespace}/ERROR`
};

export const fetchMetadata = fetchActionSimplified({
  getUrl: ({ query }) => prepareUrl(Config.api_url_metadata, {
    query: encodeURIComponent(query)
  }),

  actions: [actionTypes.METADATA_REQUEST, actionTypes.METADATA_RECEIVED, actionTypes.METADATA_ERROR]
});

export default createReducer({}, (builder) => {
  builder
    .addCase(actionTypes.METADATA_REQUEST, (draft, action) => {
      draft[action.query] = action;
    })
    .addCase(actionTypes.METADATA_ERROR, (draft, action) => {
      draft[action.query] = action;
    })
    .addCase(actionTypes.METADATA_RECEIVED, (draft, action) => {
      draft[action.query] = {
        domain: action.query,
        updatedAt: action.updatedAt,
        inProgress: action.inProgress,
        data: action.res
      };
    });
});
