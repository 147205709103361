import PropTypes from 'prop-types';
import React from 'react';

import Spinner from './spinner';

const SpinnerInBlock = ({ className = '', ...props }) => (
  <div className={className}>
    <div className='text-center'>
      <Spinner {...props}/>
    </div>
  </div>
);

SpinnerInBlock.displayName = 'SpinnerInBlock';
SpinnerInBlock.propTypes = {
  className: PropTypes.string
};

export default SpinnerInBlock;
