/**
 * Convert datetime to timestamp
 *
 * @param datetime
 * @returns {string}
 */
export default function datetime2timestamp (datetime) {
  return [
    datetime.getUTCFullYear(),
    (datetime.getUTCMonth() + 1).toString().padStart(2, '0'),
    datetime.getUTCDate().toString().padStart(2, '0'),
    datetime.getUTCHours().toString().padStart(2, '0'),
    datetime.getUTCMinutes().toString().padStart(2, '0'),
    datetime.getUTCSeconds().toString().padStart(2, '0')
  ].join('');
}
