/**
 * Search v2 results contain items in `hits` key.
 * Search v1 results do not have that. We support both with this util function.
 */
export function getSearchItemsResult (res) {
  if ('hits' in res) {
    return res.hits;
  } else {
    return res;
  }
}
