import './sentry-error-boundary.less';

import PropTypes from 'prop-types';
import React from 'react';

import { WaybackMachineLogo } from '../components/logo';

export class SentryErrorBoundary extends React.Component {
  static displayName = 'SentryErrorBoundary';

  static propTypes = {
    children: PropTypes.node.isRequired
  };

  constructor (props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch (error, errorInfo) {
    this.setState({ error });

    if (window.Raven) {
      window.Raven.captureException(error, { extra: errorInfo });
    }
  }

  _handleClick () {
    if (!window.Raven) {
      return;
    }

    window.Raven.lastEventId() && window.Raven.showReportDialog();
  }

  render () {
    if (this.state.error) {
      // render fallback UI
      return (
        <div
          className="error-boundary-message"
        >
          <WaybackMachineLogo/>
          <p>We&apos;re sorry — something&apos;s gone wrong.</p>
          <p>Our team has been notified.</p>
          <p>
            <a type="button" className='btn btn-default' href="/">bring me back</a>&nbsp;
            <button type="button" className="btn btn-primary" onClick={this._handleClick}>Report</button>
          </p>
        </div>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
