import PropTypes from 'prop-types';
import React from 'react';

const ErrorAndRetrySmall = ({ className = '', error, onRetry }) => (
  <div className={`text-center text-danger ${className}`}>
    <span className="error-and-retry-caption-sm">{error}</span>
    <button type="button" className="btn btn-sm btn-primary" onClick={onRetry}>Retry</button>
  </div>
);

ErrorAndRetrySmall.displayName = 'ErrorAndRetrySmall';

ErrorAndRetrySmall.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired
};

export default ErrorAndRetrySmall;
