import './footer.less';
import React from 'react';

const Footer = () => {
  return (
    <div className="wbs__web_footer">
      <a className="footer-item" href="https://help.archive.org/help/category/the-wayback-machine/">FAQ</a> |{' '}
      <a className="footer-item" href="https://archive.org/about/contact.php">Contact Us</a> |{' '}
      <a className="footer-item" href="https://archive.org/about/terms.php">Terms of Service (Dec 31, 2014)</a>
    </div>
  );
};

Footer.displayName = 'Footer';

export default React.memo(Footer);
