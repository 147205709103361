import has from 'lodash/has';
import Config from '../config';

/**
 * Runtime construct template
 *
 * @param queryTypes
 * @param timestamp
 * @param text
 *
 * @returns {string}
 */
export function templateWebSlashTimestampSlashText ({ queryTypes, timestamp, text }) {
  // skip timestamp when we have default year
  if (timestamp === `${Config.calendar.last_year}*`) {
    timestamp = '*';
  }

  // skip file type in default case
  if (queryTypes === Config.search_v2.search_types.default) {
    return `/web/${timestamp}/${text}`;
  }

  return `/web/${timestamp}/${text} filetype:${queryTypes}`;
}

export function templateSearchText ({ queryTypes, collection, text }) {
  // skip file type in default case
  // search collections may have custom default values defined in config.
  if (has(Config.search_v2.search_collections.options, collection)) {
    if (has(Config.search_v2.search_collections.options[collection], ['search_types', 'default'])) {
      const defaultValue = Config.search_v2.search_collections.options[collection].search_types.default;
      if (queryTypes === defaultValue) {
        return `/collection-search/${collection}/${text}`;
      }
    }
  }

  if (queryTypes === Config.search_v2.search_types.default) {
    return `/collection-search/${collection}/${text}`;
  }

  return `/collection-search/${collection}/${text} filetype:${queryTypes}`;
}

export function templateWebDiffTs1SlashTs2SlashText ({ match, location }) {
  const ts1 = match.params[0];
  const ts2 = match.params[1];
  const url = match.params[2];
  return `/web/diff/${ts1}/${ts2}/${url}`;
}
