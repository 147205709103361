import './sparkline-fallback.less';

import React, { memo } from 'react';

const SparklineFallback = () => (
  <div className="sparkline-fallback"/>
);

SparklineFallback.displayName = 'SparklineFallback';

export default memo(SparklineFallback);
