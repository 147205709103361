import splitTimestamp from './timestamp-split';

/**
 * Extract month of timestamp
 *
 * @param timestamp
 * @returns {number}
 */
export default function timestampToMonth (timestamp) {
  return Number.parseInt(splitTimestamp(timestamp)[1]);
}
