/**
 * Works with metadata about hosts
 */
import { createReducer } from '@reduxjs/toolkit';
import Config from '../../config';
import { prepareUrl } from '../../utils/api-url-processor';
import { fetchActionSimplified } from '../../utils/fetch-action';

const namespace = 'ENTITIES/LAST_TEN_CAPTURES_REQUEST';

export const actionTypes = {
  LAST_TEN_CAPTURES_REQUEST: `${namespace}/REQUEST`,
  LAST_TEN_CAPTURES_RECEIVED: `${namespace}/RECEIVED`,
  LAST_TEN_CAPTURES_ERROR: `${namespace}/ERROR`
};

export const fetchLastTenCaptures = fetchActionSimplified({
  getUrl: ({ query }) => prepareUrl(Config.api_url_last_ten_captures, {
    query: encodeURIComponent(query)
  }),
  actions: [actionTypes.LAST_TEN_CAPTURES_REQUEST, actionTypes.LAST_TEN_CAPTURES_RECEIVED, actionTypes.LAST_TEN_CAPTURES_ERROR]
});

export default createReducer({}, (builder) => {
  builder
    .addCase(actionTypes.LAST_TEN_CAPTURES_REQUEST, (draft, action) => {
      draft[action.query] = action;
    })
    .addCase(actionTypes.LAST_TEN_CAPTURES_ERROR, (draft, action) => {
      draft[action.query] = action;
    })
    .addCase(actionTypes.LAST_TEN_CAPTURES_RECEIVED, (draft, action) => {
      draft[action.query] = {
        domain: action.query,
        updatedAt: action.updatedAt,
        inProgress: action.inProgress,
        data: action.res
      };
    });
});
