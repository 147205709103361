import {
  combineReducers
} from 'redux';

import captures from './captures';
import collections from './collections';
import diff from './diff';
import hosts from './hosts';
import metadata from './metadata';
import searchResult from './search-result';
import sitemaps from './sitemaps';
import urlDetails from './urls-details';
import lastTenCaptures from './last-ten-captures';

export default combineReducers({
  captures,
  collections,
  diff,
  hosts,
  metadata,
  searchResult,
  sitemaps,
  urlDetails,
  lastTenCaptures
});
