export function restoreStatus (status) {
  if (status < 10) {
    return status * 100;
  }
  if (status < 100) {
    return status * 10;
  }
  return status;
}

export function betterStatus (statusA, statusB) {
  if (statusA === '-') {
    return statusB;
  }
  if (statusB === '-') {
    return statusA;
  }
  if (statusA < statusB) {
    return statusA;
  } else {
    return statusB;
  }
}
