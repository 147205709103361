import { faFileAlt, faFileImage, faFileAudio, faFileVideo, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Config from '../config';

/**
 * reflect file type / mime type to preview images source
 */
const contentTypesSchemes = [{
  type: ['homepage', { topType: 'text' }],
  preview: (snapshot) => ({
    imageSrc: Config.url_thumb.replace('{{item}}', snapshot.host),
    faIcon: faFileAlt
  })
}, {
  type: { topType: 'text' },
  preview: (snapshot) => ({
    imageSrc: Config.url_thumb.replace('{{item}}', snapshot.host),
    faIcon: faFileAlt
  })
}, {
  type: { topType: 'image' },
  preview: (snapshot) => ({
    imageSrc: Config.url_playback
      // display the last capture and not snapshot.first_captured because
      // we no longer have this information in the S2 index.
      .replace('{{date}}', '2')
      .replace('{{url}}', snapshot.url),
    faIcon: faFileImage
  })
}, {
  type: { topType: 'audio' },
  preview: (snapshot) => ({
    // imageSrc: null,
    // We display the screenshot of the host for a more colorful result.
    imageSrc: Config.url_thumb.replace('{{item}}', snapshot.host),
    faIcon: faFileAudio
  })
}, {
  type: { topType: 'video' },
  preview: (snapshot) => ({
    // imageSrc: null,
    // We display the screenshot of the host for a more colorful result.
    imageSrc: Config.url_thumb.replace('{{item}}', snapshot.host),
    faIcon: faFileVideo
  })
}, {
  type: ['application/pdf'],
  preview: (snapshot) => ({
    imageSrc: null,
    faIcon: faFilePdf
  })
}];

/**
 * if we haven't find right handler here is default one
 */
const defaultContentTypesSchemes = {
  preview: (snapshot) => ({
    imageSrc: null,
    // imageSrc: Config.url_thumb.replace('{{item}}', snapshot.host),
    faIcon: faFileAlt
  })
};

/**
 * get right handler with preview image generator for certain types
 *
 * @param types
 * @returns {*}
 */
export function getHandler (types) {
  // utility method to check contentTypesSchemes.
  function checkSchemes (myname) {
    const name = myname.split('/')[0];
    for (let i = 0; i < contentTypesSchemes.length; i++) {
      if (Array.isArray(contentTypesSchemes[i].type) && contentTypesSchemes[i].type.includes(name)) {
        return contentTypesSchemes[i];
      } else if (typeof contentTypesSchemes[i].type === 'object' && contentTypesSchemes[i].type.topType === name) {
        return contentTypesSchemes[i];
      }
    }
    return null;
  }

  if (Array.isArray(types)) {
    for (let i = 0; i < types.length; i++) {
      const res = checkSchemes(types[i]);
      if (res !== null) {
        return res;
      }
    }
  } else {
    const res = checkSchemes(types);
    if (res !== null) {
      return res;
    }
  }
  return defaultContentTypesSchemes;
}
