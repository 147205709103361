export default function decompress (source) {
  if (!source) {
    return null;
  }

  const { captures, hashes, ...rest } = source;
  if (!captures || captures.length === 0) {
    return source;
  }

  const [year, ...capturesOfMonths] = captures[0];
  const flatCaptures = capturesOfMonths.reduce((acc, [monthId, ...daysOfMonth]) => {
    return acc.concat(daysOfMonth.reduce((acc, [dayId, ...capturesOfDay]) => {
      return acc.concat(capturesOfDay.map(([tailOfTimestamp, hashIndex]) => {
        const hash = hashes[hashIndex];
        const timestamp = [
          year,
          monthId.toString().padStart(2, '0'),
          dayId.toString().padStart(2, '0'),
          tailOfTimestamp
        ].join('');

        return [timestamp, hash];
      }));
    }, []));
  }, []);

  return {
    captures: flatCaptures,
    ...rest
  };
}
