import { createReducer } from '@reduxjs/toolkit';
import has from 'lodash/has';
import get from 'lodash/get';
import Config from '../../config';
import * as searchRequestSelector from '../../selectors/ui/search-request';
import extractFileType from '../../utils/parsers/extract-filetype';

const namespace = 'UI/SEARCH_REQUEST';

// actions

const actionTypes = {
  UPDATE_SEARCH_INPUT_FIELD_TEXT: `${namespace}/UPDATE_SEARCH_INPUT_FIELD_TEXT`,
  UPDATE_SEARCH_TEXT: `${namespace}/UPDATE_SEARCH_TEXT`,
  SELECT_SEARCH_TYPE: `${namespace}/SELECT_SEARCH_TYPE`,
  UPDATE_SIZE_TEXT: `${namespace}/UPDATE_SIZE_TEXT`,
  UPDATE_PAGE_TEXT: `${namespace}/UPDATE_PAGE_TEXT`
};

/**
 * Update search query
 * specify collection for collection search.
 * @param query
 * @returns {function(*): *}
 */
export function updateSearchQuery (query, collection) {
  return (dispatch) => {
    const {
      filetype = get(Config, ['search_v2', 'search_collections', 'options', collection, 'search_types', 'default'],
        Config.search_v2.search_types.default),
      text
    } = extractFileType(query);

    dispatch(selectSearchType(filetype));
    query += window.location.hash;
    // note that updating text doesn't fix anything.
    //
    if (collection && has(Config, 'search_v2.search_collections.options') &&
        collection in Config.search_v2.search_collections.options) {
      return dispatch({
        type: actionTypes.UPDATE_SEARCH_TEXT,
        payload: { query, text, collection }
      });
    }

    return dispatch({
      type: actionTypes.UPDATE_SEARCH_TEXT,
      payload: { query, text }
    });
  };
}

/**
 * update search request from immediate query text state (search input)
 *
 * @returns {Function}
 */
export function updateSearchTextByImmediateText () {
  // note: now updateSearchQuery takes collection as an argument.
  // need to keep the current value of it. collection could have been
  // a state value separate from query.
  // note: window.location.hash should be added in `updateSearchQuery`
  // because the URL isn't updated correectly when you add it here.
  return (dispatch, getState) => {
    const { text } = searchRequestSelector.getImmediateQuery(getState());
    const collection = searchRequestSelector.getSubmittedQueryCollection(getState());
    dispatch(updateSearchQuery(text, collection));
  };
}

export function updateSearchSize (text) {
  return {
    type: actionTypes.UPDATE_SIZE_TEXT,
    payload: { text }
  };
}

export function updateSearchPage (text) {
  return {
    type: actionTypes.UPDATE_PAGE_TEXT,
    payload: { text }
  };
}

// TODO text must contain window.location.hash but the result is that
// rendering is paused if we do it via adding += window.location.hash
export function updateSearchInputFieldText (text) {
  // when you do this, the input field is updated correctly ASAP and the URL
  // is correct but the state URL doesn't have the hash. All other URLs are wrong.
  // text += window.location.hash;
  return {
    type: actionTypes.UPDATE_SEARCH_INPUT_FIELD_TEXT,
    payload: { text }
  };
}

export function selectSearchType (typeId) {
  return {
    type: actionTypes.SELECT_SEARCH_TYPE,
    payload: { typeId }
  };
}

// reducers
const initialState = {
  // query state which is used to receive search result
  query: {
    text: '',
    types: Config.search_v2.search_types.default,
    raw: '',
    collection: '',
    page: 1
  },
  // immediate state of query text from input field
  immediate: {
    text: ''
  },
  size: {
    text: 50
  },
  page: {
    text: 1
  }
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.SELECT_SEARCH_TYPE, (draft, action) => {
      draft.query.types = action.payload.typeId;
    })
    .addCase(actionTypes.UPDATE_SEARCH_TEXT, (draft, { payload }) => {
      draft.query.raw = payload.query;
      draft.query.text = payload.text;
      draft.query.collection = payload.collection;
    })
    .addCase(actionTypes.UPDATE_SEARCH_INPUT_FIELD_TEXT, (draft, action) => {
      draft.immediate.text = action.payload.text;
    })
    .addCase(actionTypes.UPDATE_PAGE_TEXT, (draft, { payload }) => {
      draft.page.text = payload.text;
      draft.query.page = payload.text;
    })
    .addCase(actionTypes.UPDATE_SIZE_TEXT, (draft, { payload }) => {
      draft.size.text = payload.text;
    });
});
