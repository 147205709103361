import {
  colorHistogram,
  findMaxFieldValue,
  getCaptureByTimestamp,
  numOfBins,
  processHistogram,
  processDistance
} from './service';

import timestampToYear from '../../utils/datetime/timestamp-to-year';

/**
 * Evaluate diff distance
 *
 * @param diff
 * @param targetTimestamp
 * @param year
 *
 * @param onProgress
 *
 * @returns {*}
 * @private
 */
export async function evaluateDiffDistance (diff, targetTimestamp, year, { onProgress } = {}) {
  const targetYear = timestampToYear(targetTimestamp);
  const targetDataOrErrorOfYear = diff[targetYear];
  if (!targetDataOrErrorOfYear) {
    throw new Error('we can not evaluate distance because we do not have simhashes yet');
  }

  const targetDataOfYear = targetDataOrErrorOfYear.data;
  const targetCalendar = targetDataOfYear.calendar;
  const targetCapture = targetCalendar && getCaptureByTimestamp(targetCalendar, targetTimestamp);
  const targetHash = targetCapture && targetCapture.hash;

  const dataOfYear = diff[year];

  if (!dataOfYear.data) {
    throw new Error('TODO: why that?');
  }

  // recalculate distance to all snapshots
  const calendar = await processDistance(dataOfYear.data.calendar, targetHash, {
    onProgress
  });

  const maxValue = Math.max(numOfBins, findMaxFieldValue(calendar));

  let histogram = processHistogram({
    data: calendar,
    maxValue,
    size: 10
  });

  histogram = colorHistogram({ histogram, maxValue });

  return {
    calendar,
    histogram,
    maxValue
  };
}
