import URI from 'urijs';

/**
 * Utility method used in summary page to find if query type is
 * host, domain or tld. Return vars:
 * query: param passed to back-end
 * queryValue: param display in bold main page title (e.g. host: example.com).
 * queryType: 'host', 'domain', 'tld'
 */
export function detectSummaryQuery (url) {
  if (!url) {
    return { query: '', queryValue: '', queryType: '' };
  }
  let uri;
  let query;
  let queryValue;
  let queryType;
  if (url.indexOf('domain:') === 0) {
    url = addHttp(url.substring(7));
    uri = new URI(url);
    query = 'domain:' + uri.domain();
    queryValue = uri.domain();
    queryType = 'domain';
  } else if (url.indexOf('tld:') === 0) {
    query = 'tld:' + url.substring(4);
    queryValue = url.substring(4);
    queryType = 'tld';
  } else if (url.indexOf('host:') === 0) {
    url = addHttp(url.substring(5));
    uri = new URI(url);
    query = 'host:' + uri.hostname();
    queryValue = uri.hostname();
    queryType = 'host';
  } else {
    // if there is no (domain:|tld:|host:) prefix, don't do any processing.
    // Probably, its a host.
    url = addHttp(url);
    uri = new URI(url);
    query = uri.hostname();
    queryValue = uri.hostname();
    queryType = 'host';
  }
  return { query, queryValue, queryType };
}

/**
 * Utility function to add http if its missing. Its required for URI() to work.
 */
export function addHttp (url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = 'http://' + url;
  }
  return url;
}
