import { sizeOfChunk } from './consts';

class ManhattanDistanceError extends Error {
  constructor (...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ManhattanDistanceError);
    }
  }
}

/**
 * Manhattan distance between arrays
 *
 * @param arrayA
 * @param arrayB
 * @returns {number}
 */
export function manhattanDistanceOfHash (arrayA, arrayB) {
  const size = lengthOf(arrayA);
  if (size !== lengthOf(arrayB)) {
    throw new ManhattanDistanceError('We can not calculate Manhattan distance for arrays with in different dimension');
  }

  let distance = 0;

  const valueA = valueAt(arrayA);
  const valueB = valueAt(arrayB);

  for (let i = 0; i < size; i++) {
    distance += Math.abs(valueA(i) - valueB(i));
  }

  return distance;
}

/**
 * length of hash
 *
 * @param a
 * @returns {*}
 */
function lengthOf (a) {
  if (Array.isArray(a)) {
    return a.length;
  }

  if (a instanceof Uint8Array) {
    return a.length * sizeOfChunk;
  }

  throw new ManhattanDistanceError('manhattanDistanceOfHash supports Arrays and Uint8Array types only');
}

/**
 * single dimension value of hash
 *
 * @param a
 * @returns {*}
 */
function valueAt (a) {
  if (Array.isArray(a)) {
    return idx => a[idx];
  } else {
    return idx => (a[idx >> 3] >> [7 - idx & 7]) & 1;
  }
}
