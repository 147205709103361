import './revolving-landing-fallback.less';

import React, { memo } from 'react';

const RevolvingLandingFallback = () => (
  <>
    <div className="revolving-landing-fallback"/>
    <div className="feature-list-fallback">
      <div className="feature-list-item-fallback"/>
      <div className="feature-list-item-fallback"/>
      <div className="feature-list-item-fallback"/>
    </div>
  </>
);

RevolvingLandingFallback.displayName = 'RevolvingLandingFallback';

export default memo(RevolvingLandingFallback);
