import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as calendarSelector from '../selectors/ui/calendar';
import { isQueryUrl, isQueryUrlValid } from '../selectors/ui/hosts';
import { getSubmittedQueryText } from '../selectors/ui/search-request';
import { prepareUrl } from '../utils/api-url-processor';
import { getDisplayName } from '../utils/get-display-name';
import { timestampToStr } from '../utils/timestamp-url-parser';

export function redirectWhenIsNotUrl ({ to }) {
  return (WrappedComponent) => {
    const RedirectWhenIsNotUrl = (props) => {
      const {
        calendarSelection,
        isQueryUrlValid, isUrl,
        text
      } = props;

      if (!isUrl && isQueryUrlValid) {
        let timestamp;

        if (calendarSelection) {
          timestamp = timestampToStr({ equal: calendarSelection });
        } else {
          timestamp = '*';
        }

        return <Redirect to={prepareUrl(to, { text, timestamp })}/>;
      }

      return <WrappedComponent {...props}/>;
    };

    RedirectWhenIsNotUrl.displayName = `RedirectWhenIsNotUrl(${getDisplayName(WrappedComponent)})`;
    RedirectWhenIsNotUrl.propTypes = {
      calendarSelection: PropTypes.object,
      isQueryUrlValid: PropTypes.bool,
      isUrl: PropTypes.bool,
      text: PropTypes.string
    };

    return connect(
      (state, props) => ({
        calendarSelection: calendarSelector.getSelected(state, props),
        isQueryUrlValid: isQueryUrlValid(state, props),
        isUrl: isQueryUrl(state, props),
        text: getSubmittedQueryText(state, props)
      })
    )(RedirectWhenIsNotUrl);
  };
}
