import './view-navbar.less';

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { prepareUrl } from '../../utils/api-url-processor';
import { isFeatureEnabled } from '../../config';

import { types } from '../../utils/query-type';

const options = [{
  id: 'calendar',
  url: '/web/{{timestamp}}/{{url}}',
  title: 'Calendar',
  hideOn: [types.DOMAIN, types.HOST, types.TLD, types.WAYBACK_SUMMARY]
}, {
  id: 'collections',
  url: '/web/collections/{{timestamp}}/{{url}}',
  title: 'Collections',
  hideOn: [types.DOMAIN, types.HOST, types.TLD, types.WAYBACK_SUMMARY]
}, {
  id: 'changes',
  url: '/web/changes/{{url}}',
  title: 'Changes',
  isVisible: () => isFeatureEnabled('changes_calendar'),
  hideOn: [types.DOMAIN, types.HOST, types.TLD, types.WAYBACK_SUMMARY]
}, {
  id: 'details',
  url: '/details/{{url}}',
  title: 'Summary'
}, {
  id: 'sitemap',
  url: '/web/sitemap/{{url}}',
  title: 'Site Map',
  hideOn: [types.DOMAIN, types.TLD, types.WAYBACK_SUMMARY]
}, {
  id: 'urlquery',
  url: '/web/*/{{url}}*',
  title: 'URLs',
  isVisible: () => isFeatureEnabled('url_query'),
  hideOn: [types.DOMAIN, types.TLD, types.WAYBACK_SUMMARY]
}];

const ViewNavBar = ({
  explicitQueryType, selected, url, timestamp
}) => {
  const list = options
    .filter(({ isVisible }) => !isVisible || isVisible())
    .filter(({ hideOn }) =>
      !hideOn || !hideOn.includes(explicitQueryType)
    )
    .map(opt => {
      const classes = ['navbar-option', 'beta-badge-container'];
      if (opt.beta) {
        classes.push('beta-feature');
      }

      if (opt.id === selected) {
        classes.push('selected-option');
        return (
          <span
            className={classes.join(' ')}
            key={opt.id}
            title={`${opt.title} of ${url}`}
          >
            <span>{ opt.title }</span>
          </span>
        );
      } else {
        classes.push('not-selected-option');
        return (
          <span
            className={classes.join(' ')}
            key={opt.id}
            title={`${opt.title} of ${url}`}
          >
            <Link to={prepareUrl(opt.url, { url, timestamp })}>{ opt.title }</Link>
          </span>
        );
      }
    });

  if (list.length <= 1) {
    // if there is only one option, we don't need to show it
    return <nav className="view-navbar"/>;
  }

  return (
    <nav className="view-navbar">{list}</nav>
  );
};

ViewNavBar.displayName = 'ViewNavBar';
ViewNavBar.propTypes = {
  calendarSelection: PropTypes.object,
  explicitQueryType: PropTypes.string,
  selected: PropTypes.string,
  url: PropTypes.string.isRequired,
  timestamp: PropTypes.string
};

export default React.memo(ViewNavBar);
