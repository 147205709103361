/**
 * We have number of capture and try to find the maximum distance variation
 * between mean value and all other.
 *
 * Because simhash uses hamming distance
 * we are using manhattan distance here
 *
 * @param captures
 * @returns {number}
 */

import { logError } from '../../utils/failure-logger';

import { manhattanDistanceOfHash } from './manhattan-distance-of-hash';
import { meanOfHashes } from './mean-of-hashes';

/**
 * Find Max hash variation of captures
 *
 * @param captures
 * @param ctx used for logging in case of failure
 * @return {number}
 */
export function maxHashVariation (captures = [], ctx = {}) {
  // const l = captures.length;
  // const startTime = Date.now();

  const mean = meanOfHashes(captures);
  // const variation = new Array(lengthOfHash);
  let maxVariation = 0;

  for (let i = 0; i < captures.length; i++) {
    try {
      const hashVariation = manhattanDistanceOfHash(captures[i].hash, mean);
      if (maxVariation < hashVariation) {
        maxVariation = hashVariation;
      }
    } catch (error) {
      console.warn(`Manhattan distance failed because of "${error}" for hash "${captures[i].hash}" of ${JSON.stringify(ctx)}`);
      logError(error, {
        ctx,
        mean,
        hash: captures[i].hash
      });
    }
  }

  // const delta = Date.now() - startTime;
  // console.log(`diff:variation:maxHashVariation [${l}, ${delta}]`); // eslint-disable-line no-console

  return maxVariation;
}
