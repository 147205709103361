import has from 'lodash/has';
import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { detectSummaryQuery } from '../../utils/detect-summary-query';
import { extractExplicitQueryType, types } from '../../utils/query-type';

import { getHosts } from '../entities/hosts';

const getSearchRequest = state => state.ui.searchRequest;

export const getImmediateQuery = createSelector(
  [getSearchRequest],
  (state) => state.immediate
);

export const getImmediateQueryText = createSelector(
  [getSearchRequest],
  (state) => state.immediate.text
);

export const getSubmittedQuery = createSelector(
  [getSearchRequest],
  (state) => state.query
);

export const getSubmittedRawQuery = createSelector(
  [getSearchRequest],
  (state) => state.query.raw
);

export const getSubmittedQueryText = createSelector(
  [getSearchRequest],
  (state) => state.query.text
);

export const getSubmittedQuerySize = createSelector(
  [getSearchRequest],
  (state) => state.size.text
);

export const getSubmittedQueryPage = createSelector(
  [getSearchRequest],
  (state) => state.page.text
);

export const getSubmittedQueryTypes = createSelector(
  [getSubmittedQuery],
  (query) => query.types
);

export const getSubmittedQueryCollection = createSelector(
  [getSubmittedQuery],
  (query) => query.collection
);

export const getSubmittedQueryTypesAndText = createSelector(
  [getSubmittedQueryTypes, getSubmittedQueryText],
  (types, text) => ({ types, text })
);

export const getQueryWithType = createSelector(
  [getSubmittedQueryText],
  queryText => detectSummaryQuery(queryText).query || types.WAYBACK_SUMMARY
);

export const getQueryValue = createSelector(
  [getSubmittedQueryText],
  queryText => detectSummaryQuery(queryText).queryValue
);

export const getQueryType = createSelector(
  [getSubmittedQueryText],
  queryText => detectSummaryQuery(queryText).queryType || types.WAYBACK_SUMMARY
);

export const getExplicitQueryType = createSelector(
  [getSubmittedQueryText],
  queryText => extractExplicitQueryType(queryText)
);

const isQueryUrl = createSelector(
  [getHosts, getSubmittedQueryText],
  (hosts, queryText) => get(hosts, [queryText, 'isUrl'], false)
);

const isQueryUrlValid = createSelector(
  [getHosts, getSubmittedQueryText],
  (hosts, queryText) => has(hosts, [queryText, 'isUrl'])
);

export const getImplicitQueryType = createSelector(
  [isQueryUrlValid, isQueryUrl],
  (isQueryUrlValidValue, isUrlValue) => (isQueryUrlValidValue && isUrlValue) ? types.URL : null
);
