import { createReducer } from '@reduxjs/toolkit';
import Config from '../../config';
import { prepareUrl } from '../../utils/api-url-processor';
import { fetchActionSimplified } from '../../utils/fetch-action';

const namespace = 'ENTITIES/COLLECTIONS';

const actionTypes = {
  COLLECTIONS_METADATA_REQUEST: `${namespace}/METADATA_REQUEST`,
  COLLECTIONS_METADATA_RECEIVED: `${namespace}/METADATA_RECEIVED`,
  COLLECTIONS_TITLE_REQUEST: `${namespace}/TITLE_REQUEST`,
  COLLECTIONS_TITLE_RECEIVED: `${namespace}/TITLE_RECEIVED`,
  COLLECTIONS_TITLE_ERROR: `${namespace}/TITLE_ERROR`
};

/**
 * request collection title
 */
export const fetchCollectionTitle = fetchActionSimplified({
  getUrl: ({ id }) => prepareUrl(Config.api_url_collection_title, {
    collectionId: id
  }),

  actions: [
    actionTypes.COLLECTIONS_TITLE_REQUEST,
    actionTypes.COLLECTIONS_TITLE_RECEIVED,
    actionTypes.COLLECTIONS_TITLE_ERROR
  ]
});

export default createReducer({}, (builder) => {
  builder
    .addCase(actionTypes.COLLECTIONS_TITLE_REQUEST, (draft, action) => {
      if (!draft[action.id]) {
        draft[action.id] = {};
      }
      draft[action.id].inProgress = true;
      draft[action.id].updatedAt = action.updatedAt;
      draft[action.id].error = null;
    })
    .addCase(actionTypes.COLLECTIONS_TITLE_RECEIVED, (draft, action) => {
      if (!draft[action.id]) {
        draft[action.id] = {};
      }
      draft[action.id].inProgress = false;
      draft[action.id].updatedAt = action.updatedAt;
      draft[action.id].metadata = {
        title: action.res.result
      };
    })
    .addCase(actionTypes.COLLECTIONS_TITLE_ERROR, (draft, { error, id, updatedAt }) => {
      if (!draft[id]) {
        draft[id] = {};
      }
      draft[id].inProgress = false;
      draft[id].updatedAt = updatedAt;
      draft[id].error = error;
    });
});
