import splitTimestamp from './timestamp-split';

/**
 * Extract day of timestamp
 *
 * @param timestamp
 * @returns {number}
 */
export default function timestampToDay (timestamp) {
  return Number.parseInt(splitTimestamp(timestamp)[2]);
}
