import './wayback-machine-logo.less';

import React, { memo } from 'react';

const WaybackMachineLogo = () => (
  <div
    className="search-toolbar-logo"
    alt="logo: Internet Archive's Wayback Machine"
  />
);

WaybackMachineLogo.displayName = 'WaybackMachineLogo';

export default memo(WaybackMachineLogo);
