import timestamp2datetime from './timestamp-to-datetime';

import { LONG, SHORT } from './months';

const FIELDS = {
  Y: function (d) {
    return d.getUTCFullYear();
  },
  m: function (d) {
    return d.getUTCMonth() + 1;
  },
  b: function (d) {
    return SHORT[d.getUTCMonth()];
  },
  B: function (d) {
    return LONG[d.getUTCMonth()];
  },
  d: function (d) {
    return d.getUTCDate();
  },
  H: function (d) {
    return ('0' + d.getUTCHours()).slice(-2);
  },
  M: function (d) {
    return ('0' + d.getUTCMinutes()).slice(-2);
  },
  S: function (d) {
    return ('0' + d.getUTCSeconds()).slice(-2);
  },
  '%': function () {
    return '%';
  }
};

export default function formatTimestamp (timestamp, fmt) {
  const datetime = timestamp2datetime(timestamp);
  return fmt.replace(/%./g, function (ph) {
    const field = FIELDS[ph[1]];
    return field ? field(datetime) : ph;
  });
}
