/**
 * log error to Sentry error logger
 *
 * @param error   javascript Error object
 * @param extra
 */
export function logError (error, extra = {}) {
  console.error(error);
  if (window.Raven && window.Raven.captureException) {
    window.Raven.captureException(
      error, { extra }
    );
  }
}
