import inUTC from './in-utc';
import splitTimestamp from './timestamp-split';

/**
 * Convert timestamp to datetime
 *
 * @param timestamp
 * @returns {Date}
 */
export default function timestamp2datetime (timestamp) {
  const tsArray = splitTimestamp(timestamp);
  return inUTC(
    tsArray[0], tsArray[1] - 1, tsArray[2],
    tsArray[3], tsArray[4], tsArray[5]
  );
}
