/* eslint-disable react/jsx-no-target-blank */
// in this case we still on IA pages

import './donation.less';

import PropTypes from 'prop-types';
import React from 'react';

export class DonationLink extends React.PureComponent {
  static displayName = 'DonationLink';

  static propTypes = {
    linkStyle: PropTypes.string,
    page: PropTypes.string,
    origin: PropTypes.string
  };

  tracker (style) {
    return (this.props.page || 'Wayback') + '|' + (style || 'DonateButton');
  }

  linkUrl() {
    const origin = this.props.origin;
    if (origin) {
      return `//archive.org/donate/?origin=${encodeURIComponent(origin)}`;
    } else {
      return '//archive.org/donate/';
    }
  }

  render () {
    if (this.props.linkStyle === 'button2') {
      // new style Mar 2018
      return (
        <div id="donation-link">
          <span className="web_heading">Is the Wayback Machine useful to you? Then keep it growing. Your gift matched today!</span>
          <a
            className="beta_button"
            href={this.linkUrl()}
            data-event-click-tracking={this.tracker()}
          >
            DONATE
          </a>
        </div>
      );
    } else if (this.props.linkStyle === 'text') {
      // simple text only style
      return (
        <p id="donation-link">
          <a href={this.linkUrl()} target="_blank" data-event-click-tracking={this.tracker('DonateText')}>
            <strong>PLEASE DONATE TODAY.</strong> Your generosity preserves
            knowledge for future generations.
            Thank&nbsp;you.
          </a>
        </p>
      );
    } else {
      // simple button only style
      return (
        <div id="donation-link">
          <a
            href={this.linkUrl()}
            className="donation-button"
            data-event-click-tracking={this.tracker()}
          >
            <span className="beta_button">DONATE</span>
          </a>
        </div>
      );
    }
  }
}
