import './welcome-fallback.less';

import React, { memo } from 'react';

const WelcomeFallback = () => (
  <div className="welcome-panel-fallback">
    <div className="welcome-panel-fallback-item"/>
    <div className="welcome-panel-fallback-item"/>
    <div className="welcome-panel-fallback-item"/>
    <div className="welcome-panel-fallback-item"/>
    <div className="welcome-panel-fallback-item"/>
    <div className="welcome-panel-fallback-item"/>
  </div>
);

WelcomeFallback.displayName = 'WelcomeFallback';

export default memo(WelcomeFallback);
