/* eslint-disable camelcase */

/**
 * A global singleton of config variables for the component.
 * it is default settings - should be overwritten at certain application
 */
const config = {
  api_url_all_captures_of_a_date: '/__wb/calendarcaptures/2?url={{url}}&date={{date}}',
  api_url_anchor: '/__wb/search/anchor?q={{query}}&size={{size}}',
  api_url_calendar_captures: '/__wb/calendarcaptures?url={{url}}&selected_year={{year}}',
  api_url_captures_distribution_of_year: '/__wb/calendarcaptures/2?url={{url}}&date={{year}}&groupby=day',
  api_url_captures_distribution_of_month: '/__wb/calendarcaptures/2?url={{url}}&date={{year}}{{month}}&groupby=day',
  api_url_collection_metadata: 'https://archive.org/metadata/{{collectionId}}/metadata',
  api_url_collection_title: 'https://archive.org/metadata/{{collectionId}}/metadata/title',
  api_url_collections_captures_of_year: '/__wb/calendarcaptures/2?url={{url}}&date={{year}}&groupby=collection,day',
  api_url_digests: '/cdx/?url={{url}}&output=json&filter=statuscode:200&fl=digest,timestamp',
  api_url_explore: '/__wb/explore2?url=',
  api_url_host: '/__wb/search/host?q={{query}}',
  api_url_first_capture: '/cdx/search?url={{url}}&fl=timestamp&limit=1&filter=statuscode:200',
  api_url_last_capture: '/cdx/search?url={{url}}&fl=timestamp&limit=-1&filter=statuscode:200',
  api_url_metadata: '/__wb/search/metadata?q={{query}}',
  api_url_last_ten_captures: {
    url:'/cdx/search/cdx?url={{query}}&output=json&limit=-10&&fl=timestamp,statuscode,mimetype,length',
    cross_domain: true
  },

  api_url_search: {
    url: '/__wb/search/waybacksearch?q={{url}}&size={{size}}&filetype={{types}}',
    // just temporal solution for the moment when we have special invitation token
    // which we should share in cookies with request.
    // By default we are not exposing cookies with request.
    cross_domain: true
  },
  api_url_search_collection: {
    url: '/__wb/search/waybacksearch?q={{url}}&size={{size}}&page={{page}}&filetype={{types}}&collection={{collection}}',
    cross_domain: true
  },
  api_url_search_collectioninfo: {
    url: '/__wb/search/collectioninfo?collection={{collection}}',
    cross_domain: true
  },
  api_url_simhash: 'https://gext-api.archive.org/services/simhash/{{resource}}?{{ops}}&compress={{compress}}',
  api_url_sitemap: '/web/timemap/json?url={{url}}/&fl=timestamp:4,original,urlkey&matchType=prefix&filter=statuscode:200&filter=mimetype:text/html&collapse=urlkey&collapse=timestamp:4&limit=100000',
  api_url_timemap: '/web/timemap/json',
  api_url_sparkline: '/__wb/sparkline?url={{url}}&output=json',
  url_collection_details_page: 'https://archive.org/details/{{collection}}',
  url_feedback_page: 'https://archive.org/wayback/web_beta_feedback.php',
  url_playback: '/web/{{date}}/{{url}}',
  url_playback_last_screenshot: '/web/{{url}}',
  url_save_page_now: '//web.archive.org/save/{{query}}', // Trailing slash required
  url_search_page: '/web/*/{{url}}*',
  url_site_stats: '/details/{{url}}',
  url_search_mimetype: '/web/*;type={{mimetype}}/{{url}}/*',
  url_thumb: '/thumb/{{item}}?generate=1',

  // debug mode, show auxiliary components, like toggling lazy fallaback
  debug: false,

  // default debounce delay before async action
  default_debounce_delay: 250,

  // number explored web pages which is shown at the main page
  num_of_explored_web_pages: '617 billion',
  // url to the blog post about last number of saved web pages
  num_of_explored_web_pages_details_url: 'https://blog.archive.org/2016/10/23/defining-web-pages-web-sites-and-web-captures/',

  bundling: {
    // used to define webpack public path on fly https://webpack.js.org/guides/public-path#on-the-fly
    public_path: '/static/bower_components/wayback-search-js/dist'
  },

  // all brand new features
  features: {
    // options:
    // - carousel: src/components/welcome/carousel.js
    // - grid: src/components/welcome/index.js
    landing: 'grid',

    // options:
    // - v1: src/containers/search-v1/search-result-container.js
    // - v2: src/containers/search-toolbar.js and src/containers/search-result-container.js
    search: 'v1',

    // more about it: src/containers/changes.js
    changes_calendar: true,

    // Display component for prefix urlquery in calendar view. See also:
    // src/components/datatable
    url_query: true,

    // more about it: src/components/welcome/trend-feature.js
    landing_layout_with_trend: false
  },

  // each new feature should be disabled by default
  // to prevent exposition out of beta build
  default_features: {
    landing: 'carousel',
    search: 'v1',
    changes_calendar: false,
    prefix_urlquery: false,
    landing_layout_with_trend: false,
    beta_promo: false,
    wayforward_machine: false,
  },

  // 'calendar tab" at web/<year>*/<url>
  calendar: {
    captures_debounce_delay: 200,
    first_year: 1996,
    max_num_of_captures_for_single_year_request: 12 * 3000,
    last_year: new Date().getUTCFullYear(),
    close_popup_on_rollout_and_after_ms: 1000
  },

  // "captures changes calendar tab" at web/changes/<url>
  changes_calendar: {
    // mock data for test purpose and reduce load on server
    mock_data: false,
    // delay before job state request in mseconds
    delay_before_job_state_request: 2000,
    // url template which we use to playback difference between 2 pages
    url_playback: '/web/diff/{{timestampA}}/{{timestampB}}/{{url}}',
    // url template which we use to display radial tree with differences to url
    url_diffgraph_playback: '/web/diffgraph/{{date}}/{{url}}',
    // how many jobs: "find hash distance (variance)" could run in the same time
    num_of_simultaneous_evaluation_jobs: 1,
    // fetch compressed simhash
    fetch_compressed: true
  },

  // "collections calendar tab" at web/collections/<year>*/<url>
  collections: {
    skip_collections: [
      'archiveitdigitalcollection',
      'archiveitpartners',
      'archiveteam',
      'webwidecrawl',

      // it is synonym of (.*)-Collection-(.*)
      '(.*)-Partner-(.*)',
      // all those are sub-sets of "top_domains"
      'top_domains-(.*)'
    ]
  },

  // wayback-diff component options
  diff: {
    cdxServer: '/web/timemap',
    diffgraphPrefix: '/web/diffgraph/',
    sparklineURL: '/__wb/sparkline',
    limit: '2000',
    maxSunburstLevelLength: '70',
    snapshotsPrefix: '/web/',
    urlPrefix: '/web/diff/',
    waybackDiscoverDiff: 'https://gext-api.archive.org/services/simhash',
    webMonitoringProcessingURL: 'https://gext-api.archive.org/services/diff'
  },

  // fetching data in a queue
  fetch_queue: {
    // maximum number of simultaneous jobs
    num_of_simultaneous_jobs: 4
  },

  // search result page at "web/*/<not-url>"
  search: {
    suggestion: {
      // how many items in suggestion list
      max_count: 5

      // cut any query which longer than limit
      // maxLength: 120,
    },

    //
    query_min_length: 2,

    // update search result on each keystroke
    immediate: false,

    text_update_debounce_delay: 500,

    uid_field_name: 'link'
  },

  // Wayback Search v2 at "web/*/<not-url>"
  search_v2: {
    search_collections: {
      // collections available for advanced search
      options: {
        // disabled by default but kept for reference
        /**
        alexa_2007: {
          title: 'Alexa Internet 2007',
          url: 'https://archive.org/details/alexa_2007'
        },
        EndOfTerm2020PreElectionCrawls: {
          title: 'End Of Term (US Gov) 2020',
          url: 'https://archive.org/details/EndOfTerm2020PreElectionCrawls'
        },
        liveweb: {
          title: 'Save Page Now',
          url: 'https://archive.org/details/liveweb'
        },
        mediacloud: {
          title: 'Media Cloud',
          url: 'https://archive.org/details/mediacloud'
        },
        'russian-independent-media': {
          title: 'Russian Independent Media',
          url: 'https://archive.org/details/russian-independent-media',
          showSeedsOption: 1
        },
        'nytimes.com': {
          title: 'NYTimes.com',
        },
        pdf: {
          title: 'PDFs',
          intro: `
            Search #count PDF files using an index built from anchor text, all
            URL parts (file names, hosts, domains), MIME Types, HTTP Status
            codes, Web Page Titles and all the text from the first page of PDF
            documents.`,
          more: '',
          // custom search_types means custom tabs in the UI for this collection
          search_types: {
            default: 'pdf',
            horizontal_line_room: 1,
            options: [{
              id: 'pdf',
              items: [
                'application/pdf'
              ],
              title: 'PDFs',
              icon: 'iconochive-texts',
              type: 'application/pdf'
            }]
          }
        }**/
      },
      defaultIntro: `
        Welcome to the Wayback Machine collection search service. This service
        is based on indexes of specific data from selected Collections.`,
      defaultMore: `
        Collections are groupings of Web archives related to a specific
        archiving method or source of URLs. For example the
        <a href="https://archive.org/details/mediacloud">mediacloud</a>
        Collection contains URLs sourced from
        <a href="https://mediacloud.org">mediacloud.org</a>.<br /><br />
        The specific Web page data we are indexing includes: anchor text,
        all URL parts (file names, hosts, domains), MIME Types, HTTP Status
        codes & Web Page Titles.
        In this way you can search for PDF files,
        from .mil websites that have the word “nuclear” in their file name.`
    },
    search_types: {
      default: 'text',

      // how many types will be in horizontal line
      // the rest will put to the dropdown list
      horizontal_line_room: 5,

      // available options
      options: [{
        id: 'text',
        items: [
          'homepage',
          'text/html',
          'text/pl'
        ],
        title: 'Webpages',
        icon: 'iconochive-web',
        type: 'text'
      }, {
        id: 'image',
        items: [
          'gif', 'image/gif',
          'jpg', 'jpeg', 'image/jpeg',
          'png', 'image/png', 'application/png'
        ],
        title: 'Images',
        icon: 'iconochive-image',
        type: 'image'
      }, {
        id: 'audio',
        items: [
          'mp3', 'audio/mpeg', 'audio/mpeg3',
          'wav', 'audio/x-wav'
        ],
        title: 'Audio',
        icon: 'iconochive-audio',
        type: 'audio'
      }, {
        id: 'video',
        items: [
          'avi', 'video/x-msvideo',
          'mp4', 'video/mp4',
          'mpg', 'video/mpeg',
          'mov', 'video/quicktime'
        ],
        title: 'Video',
        icon: 'iconochive-movies',
        type: 'video'
      }, {
        id: 'pdf',
        items: [
          'application/pdf'
        ],
        title: 'PDFs',
        icon: 'iconochive-texts',
        type: 'application/pdf'
      }
      ]
    },

    uid_field_name: 'url'
  },

  sparkline: {
    debounce_delay: 100
  }
};
export default config;

import has from 'lodash/has';
import get from 'lodash/get';

// utility functions
// TODO: make these a method of Config class?

/**
 * return true if feature featureName is enabled.
 *
 * @param featureName
 * @returns {*|boolean}
 */
 export function isFeatureEnabled (featureName) {
  if (has(config, ['features', featureName])) {
    return get(config, ['features', featureName]);
  }
  if (has(config, ['default_features', featureName])) {
    // fallback to default
    return get(config, ['default_features', featureName]);
  }
  throw new Error(`feature ${featureName} is missed in "default_features" and "features" config group`);
}
