import PropTypes from 'prop-types';
import React from 'react';

import formatTimestamp from '../../utils/datetime/format-timestamp';
import wbUrl from '../../utils/wb_url';

const CapturesInterval = ({ first, last, url }) => {
  if (!first && !last) {
    return <span>-</span>;
  }

  const firstUrl = wbUrl(first, url);
  const lastUrl = wbUrl(last, url);
  if (first === last) {
    return (
      <a href={firstUrl}>{formatTimestamp(first, '%B %d, %Y')}.</a>
    );
  } else {
    return (
      <span>
        between <a href={firstUrl}>{formatTimestamp(first, '%B %d, %Y')}</a> and <a
          href={lastUrl}>{formatTimestamp(last, '%B %d, %Y')}</a>.
      </span>
    );
  }
};

CapturesInterval.displayName = 'CapturesInterval';
CapturesInterval.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string,
  url: PropTypes.string
};

export default React.memo(CapturesInterval);
