import './error-panel.less';

import PropTypes from 'prop-types';
import React from 'react';

const ErrorPanel = ({ title, children }) => (
  <div className="error error-border">
    <h2>{title}</h2>
    <div>{children}</div>
  </div>
);

ErrorPanel.displayName = 'ErrorPanel';
ErrorPanel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default React.memo(ErrorPanel);
