/**
 * This HOC (Higher-Order Component) patch global Config file with passed Config
 *
 */
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';

import Config from '../../config';

/**
 * add slash at the end of path
 *
 * @param p
 * @returns {*}
 */
function appendSlashAtTheEnd (p) {
  if (p && p.length > 0 && p.substr(-1) !== '/') {
    return p + '/';
  }

  return p;
}

export default class Configurable extends React.PureComponent {
  static displayName = 'Configurable';

  static propTypes = {
    children: PropTypes.node,
    config: PropTypes.object.isRequired
  };

  constructor (props) {
    super(props);
    const { config = {} } = props;

    // special case:
    // when we got empty features we should replace it with default
    if ('features' in config && isEmpty(config.features)) {
      config.features = Config.default_features;
    }

    if (has(config, 'bundling.public_path')) {
      // we should add slash at the end of path to prevent sticking to file name
      // more about this variable https://webpack.js.org/guides/public-path#on-the-fly
      // ! important we should use import() only after this initialization
      __webpack_public_path__ = appendSlashAtTheEnd(config.bundling.public_path); // eslint-disable-line camelcase
    }

    // Merge config prop into global config
    Object.assign(Config, merge(Config, config));
  }

  render () {
    return this.props.children;
  }
}
