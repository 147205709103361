import { sizeOfChunk } from './consts';

/**
 * Find mean of hashes
 * hash is binary array
 *
 * @param captures
 * @returns {*}
 */
export function meanOfHashes (captures = []) {
  if (captures.length === 0) {
    return null;
  }

  const lengthOfHash = captures[0].hash.length * sizeOfChunk;

  const mean = new Array(lengthOfHash).fill(0);

  for (let i = 0; i < captures.length; i++) {
    const hash = captures[i].hash;
    for (let j = 0; j < hash.length; j++) {
      let hashChunkIndex = sizeOfChunk - 1;
      let hashChunk = hash[j];
      while (hashChunk !== 0) {
        const meanIndex = j * sizeOfChunk + hashChunkIndex;
        mean[meanIndex] += hashChunk & 1;
        hashChunk >>= 1;
        hashChunkIndex--;
      }
    }
  }

  const numOfCaptures = captures.length;
  for (let i = 0; i < mean.length; i++) {
    mean[i] /= numOfCaptures;
  }

  return mean;
}
