/**
 * convert date (UTC) to timestamp
 *
 * @param date
 * @return {*}
 */
export default function dateToTimestamp (date) {
  return date.getUTCFullYear().toString() +
    (date.getUTCMonth() + 1).toString().padStart(2, '0') +
    date.getUTCDate().toString().padStart(2, '0');
}
