import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';

import { getId } from '../../reducers/entities/search-result';

import { getSearchResultEntities } from '../entities/search-result';
import { getUrlDetails } from '../entities/urls-details';

import * as searchRequestSelector from './search-request';

const getSearchResult = state => state.ui.searchResult;

export const getSearchResultViewType = createSelector(
  [getSearchResult],
  searchResult => searchResult.view
);

export const getSearchResultError = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => get(searchResult, [getId(query), 'error'])
);

export const getDidYouMean = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => {
    return get(searchResult, [getId(query), 'didYouMean']);
  }
);

export const getSearchResultTotal = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => {
    return get(searchResult, [getId(query), 'total'], 0);
  }
);

export const getSearchResultAggregations = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => {
    return get(searchResult, [getId(query), 'aggregations'], {});
  }
);

export const getSearchResultItems = createSelector(
  [getSearchResultEntities, getUrlDetails, searchRequestSelector.getSubmittedQuery],
  (searchResult, urlsDetails, query) => {
    if (urlsDetails) {
      return get(searchResult, [getId(query), 'items'], [])
        .map(id => urlsDetails[id]);
    } else {
      return [];
    }
  }
);

export const isNoResultFound = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => get(searchResult, [getId(query), 'noResultFound'])
);

export const isSearchResultInProgress = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => get(searchResult, [getId(query), 'inProgress'], false)
);

export const isSearchResultInvalid = createSelector(
  [getSearchResultEntities, searchRequestSelector.getSubmittedQuery],
  (searchResult, query) => get(searchResult, [getId(query), 'invalid'], true)
);

export const getSearchResultSummary = createSelector(
  [
    isSearchResultInProgress,
    isSearchResultInvalid,
    getSearchResultItems,
    getSearchResultTotal,
    getSearchResultAggregations,
    isNoResultFound,
    getSearchResultError,
    getDidYouMean
  ],
  (inProgress, invalid, items, total, aggregations, noResultFound, error, didYouMean) => ({
    inProgress, invalid, items, total, aggregations, noResultFound, error, didYouMean
  })
);
