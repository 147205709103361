import './captures-range-info.less';

import PropTypes from 'prop-types';
import React from 'react';

import { CapturesInterval } from '../calendar';

const CapturesRangeInfo = ({ first, last, url, total }) => {
  const totalLabel = total === 1 ? 'time' : 'times';
  return (
    <div className='captures-range-info'>
      Saved&nbsp;<strong>{total ? total.toLocaleString() : '-'} {totalLabel}</strong>&nbsp;
      <CapturesInterval
        first={first}
        last={last}
        url={url}
      />
    </div>
  );
};

CapturesRangeInfo.displayName = 'CapturesRangeInfo';
CapturesRangeInfo.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string,
  url: PropTypes.string,
  total: PropTypes.number
};

export default React.memo(CapturesRangeInfo);
