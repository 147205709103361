import { connect } from 'react-redux';

import SparklineWithCapturesRange from './sparkline-with-captures-range';
import { fetchCurrentUrlCapturesDistribution } from '../../reducers/entities/captures';
import { selectCalendarDate } from '../../reducers/ui/calendar';
import { highlight } from '../../reducers/ui/sparkline';
import {
  getCapturesOfSubmittedQuery,
  getSelected,
  getUrlCapturesDistributionError,
  hasUrlCapturesDistribution,
  isUrlCapturesDistributionInProgress
} from '../../selectors/ui/calendar';
import { getHighlighted } from '../../selectors/ui/sparkline';
import * as searchRequestSelector from '../../selectors/ui/search-request';

export default connect(
  (state, props) => ({
    captures: getCapturesOfSubmittedQuery(state, props),
    capturesDistributionError: getUrlCapturesDistributionError(state, props),
    capturesDistributionInProgress: isUrlCapturesDistributionInProgress(state, props),
    hasUrlCapturesDistribution: hasUrlCapturesDistribution(state, props),
    highlighted: getHighlighted(state, props),
    selected: getSelected(state, props),
    url: searchRequestSelector.getSubmittedQueryText(state, props)
  }),

  (dispatch, props) => ({
    onFetchCapturesDistribution: () => dispatch(fetchCurrentUrlCapturesDistribution()),
    onHoverDate: hovered => dispatch(highlight(hovered)),
    onSelectDate: date => dispatch(selectCalendarDate(date)),
    onUnHoverDate: () => dispatch(highlight())
  })
)(SparklineWithCapturesRange);
