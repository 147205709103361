import './search-result-fallback.less';

import React, { memo } from 'react';

const SearchResultFallback = () => (
  <>
    <div className="search-result-fallback-item"/>
    <div className="search-result-fallback-item"/>
    <div className="search-result-fallback-item"/>
    <div className="search-result-fallback-item"/>
    <div className="search-result-fallback-item"/>
    <div className="search-result-fallback-item"/>
  </>
);

SearchResultFallback.displayName = 'SearchResultFallback';

export default memo(SearchResultFallback);
