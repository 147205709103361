// selectors for app-layout
import { createSelector } from '@reduxjs/toolkit';
import { hasCapturesOfSubmittedQuery, hasCapturesOfSubmittedQueryIsValid } from './calendar';
import { isQueryUrl } from './hosts';
import { getExplicitQueryType } from './search-request';
import { getSearchResultViewType } from './search-result';

// determines whether ViewNavBar is shown - injected into AppLayout component
// (explicitQueryType || isUrl) && seletedView !== 'advancedsearch')
// TODO: currently ViewNavBar is hidden for not-archived state, but it is useful
// to have it there.
export const showViewNavBar = createSelector(
  // isQueryUrl is highly dependent on the response from /__wb/search/hosts, which is
  // only relevant for /web/*/<URL-or-query-text>.
  // getExplicitQueryType represents "type: <mimetype>" query string in v2 search.
  [getExplicitQueryType, isQueryUrl, getSearchResultViewType],
  (explicitQueryType, isUrl, selectedView) => {
    if (selectedView === 'advancedsearch') return false;
    if (selectedView === 'urlquery') return true;
    return explicitQueryType || isUrl;
  }
);

// determine whether SparklineWithCapturesRange is shown - injected into AppLayout component
// TODO: this selector is necessary just because SparklineWithCaptureRange is rendered by
// AppLayout despite it is relevant to collections and calendar only. Move rendering to
// components for those specific query types.
export const showSparkline = createSelector(
  [isQueryUrl, getSearchResultViewType],
  (isUrl, selectedView) => {
    return isUrl && ['collections', 'calendar'].includes(selectedView);
  }
);

// determine whether AppLayout should render NotArchived
// TODO: this ugly selector is necessary because AppLayout is rendering NotArchived
// despite NotArchived is applicable to some query types only. Rendering shall be moved to
// component for each query type.
export const isUrlNotArchived = createSelector(
  [getSearchResultViewType, isQueryUrl, hasCapturesOfSubmittedQueryIsValid, hasCapturesOfSubmittedQuery],
  (selectedView, isQueryUrl, hasCapturesOfSubmittedQueryIsValid, hasCapturesOfSubmittedQuery) => {
    if (!['collections', 'calendar', 'changes', 'details'].includes(selectedView)) {
      return false;
    }
    return isQueryUrl && hasCapturesOfSubmittedQueryIsValid && !hasCapturesOfSubmittedQuery;
  }
);
