import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';
import Config from '../../config';
import { hasAutoSuggestionList } from '../../selectors/ui/hosts';
import * as searchRequestSelector from '../../selectors/ui/search-request';
import { fetchActionSimplified } from '../../utils/fetch-action';
import extractFileTextAndType from '../../utils/parsers/extract-filetype';

const namespace = 'ENTITIES/HOSTS';

const actionTypes = {
  HOSTS_REQUEST: `${namespace}:REQUEST`,
  HOSTS_RECEIVED: `${namespace}:RECEIVE`,
  HOSTS_ERROR: `${namespace}:ERROR`,
  HOSTS_INVALIDATE: `${namespace}:INVALIDATE`
};

const fetchHosts = fetchActionSimplified({
  getUrl: ({ query }) => Config.api_url_host.replace('{{query}}', encodeURIComponent(query)),

  actions: [actionTypes.HOSTS_REQUEST, actionTypes.HOSTS_RECEIVED, actionTypes.HOSTS_ERROR]
});

/**
 * thunk to fetch suggestions hosts for search input immediate query text
 *
 * @returns {Function}
 */
export const fetchSuggestionsForImmediateQuery = () => (dispatch, getState) => {
  const state = getState();
  if (hasAutoSuggestionList(state)) {
    return;
  }

  const query = searchRequestSelector.getImmediateQueryText(state);

  const { text } = extractFileTextAndType(query);

  if (text === undefined || text.length < Config.search.query_min_length) {
    return;
  }

  dispatch(fetchHosts({ query: text }));
};

export const fetchSuggestionsForSubmittedQuery = () => (dispatch, getState) => {
  const query = searchRequestSelector.getSubmittedQueryText(getState());
  if (query === undefined) {
    return;
  }

  dispatch(fetchHosts({ query }));
};

/**
 * Whether good time to reload captures for certain url
 *
 * @param state
 * @param query
 * @returns {boolean}
 */
const initialState = {};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(actionTypes.HOSTS_REQUEST, (draft, action) => {
      draft[action.query] = {
        inProgress: action.inProgress,
        updatedAt: action.updatedAt
      };
    })
    .addCase(actionTypes.HOSTS_RECEIVED, (draft, action) => {
      const hosts = get(action, 'res.hosts', [])
        .slice(0, Config.search.suggestion.max_count)
        .map((item) => ({
          value: item.link,
          label: item.display_name
        }));

      draft[action.query] = {
        hosts,
        isUrl: get(action, 'res.isUrl'),
        noResultFound: hosts.length === 0,
        valid: true,
        inProgress: action.inProgress,
        updatedAt: action.updatedAt
      };
    })
    .addCase(actionTypes.HOSTS_ERROR, (draft, action) => {
      draft[action.query] = {
        error: action.error,
        valid: true,
        inProgress: action.inProgress,
        updatedAt: action.updatedAt
      };
    });
});
