import { connect } from 'react-redux';

import { AppLayout } from './app-layout';
import { fetchCurrentUrlCapturesDistribution } from '../../reducers/entities/captures';
import { fetchSuggestionsForSubmittedQuery } from '../../reducers/entities/hosts';
import { getSelected } from '../../selectors/ui/calendar';
import { getError, isQueryUrlInProgress } from '../../selectors/ui/hosts';
import * as searchRequestSelector from '../../selectors/ui/search-request';
import { getSearchResultViewType } from '../../selectors/ui/search-result';
import { isUrlNotArchived, showSparkline, showViewNavBar } from '../../selectors/ui/app-layout';

export const App = connect(
  (state, props) => ({
    ...props,
    // these values are simply passed down to ViewNavBar. Probably we can inject
    // these into ViewNavBar directly.
    calendarSelection: getSelected(state, props),
    url: searchRequestSelector.getSubmittedQueryText(state, props),
    explicitQueryType: searchRequestSelector.getExplicitQueryType(state, props),
    selectedView: getSearchResultViewType(state, props),
    //
    isUrlInProgress: isQueryUrlInProgress(state, props),
    isUrlError: getError(state, props),
    isUrlNotArchived: isUrlNotArchived(state, props),
    showViewNavBar: showViewNavBar(state, props),
    showSparkline: showSparkline(state, props)
  }),
  (dispatch, props) => ({
    onRetryIsUrl: () => dispatch(fetchSuggestionsForSubmittedQuery()),
    onFetchHasCapturesOfSubmittedQuery: () => dispatch(fetchCurrentUrlCapturesDistribution())
  })
)(AppLayout);
