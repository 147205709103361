import './sitemap-layout-fallback.less';

import React, { memo } from 'react';

const SitemapLayoutFallback = () => (
  <div className="sitemap-layout-fallback">
    <div className="sitemap-layout-fallback-headliner"/>
    <div className="sitemap-layout-fallback-note"/>
    <div className="sitemap-layout-fallback-content"/>
  </div>
);

SitemapLayoutFallback.displayName = 'SitemapLayoutFallback';

export default memo(SitemapLayoutFallback);
